import type { GadgetData } from '@atlassian/jira-dashboard-common';
import { TRACK_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { isReactKey } from '@atlassian/jira-react-gadgets-definition';

export const getGadgetAnalyticsProps = (data: GadgetData) => {
	let gadgetType = 'UNKNOWN_GADGET';

	if (data.amdModule != null) {
		gadgetType = data.amdModule;
	} else if (data.forge != null) {
		gadgetType = data.forge.key;
	} else if (isReactKey(data.reactKey)) {
		gadgetType = data.reactKey;
	}

	return {
		eventType: TRACK_EVENT_TYPE,
		actionSubjectId: 'gadget',
		actionSubject: 'gadget',
		attributes: {
			gadgetTitle: data.title,
			gadgetType,
		},
	};
};
