import { useCallback, useLayoutEffect, useEffect, useState } from 'react';
import { GADGETS_DEFINITION, isReactKey } from '@atlassian/jira-react-gadgets-definition';
import { useDashboardReactGadgetsResource } from '@atlassian/jira-router-resources-dashboard';
import { lensReactGadget } from '../utils';

export const useReactGadget = (
	gadgetId: string,
	reactKey?: string,
): {
	reinitialise: () => void;
	isEditable: boolean | null;
	isLoading: boolean | null;
} => {
	const { update, refresh, loading } = useDashboardReactGadgetsResource();
	const [resetId, setResetId] = useState<string | null>(null);
	const [namedState, setNamedState] = useState<'idle' | 'update' | 'refresh' | 'loading'>('idle');
	const isReactGadget = isReactKey(reactKey);

	const reinitialise = useCallback(() => {
		if (namedState === 'idle') {
			// IMPORTANT - Mutations to resource while loading will be lost
			if (loading) {
				setResetId(gadgetId);
				setNamedState('update');
			} else {
				// IMPORTANT - Set null in the resource and then refresh to remove the nulls
				update(lensReactGadget(gadgetId, null));
				setNamedState('refresh');
			}
		}
	}, [gadgetId, loading, update, namedState]);

	useLayoutEffect(() => {
		if (!loading && resetId != null && namedState === 'update') {
			setResetId(null);
			// IMPORTANT - Set null in the resource and then refresh to remove the nulls
			update(lensReactGadget(resetId, null));
			setNamedState('refresh');
		}
	}, [loading, update, resetId, namedState]);

	useEffect(() => {
		// IMPORTANT - We need to arrive here asynchronously in case multiple gadgets are reset at once
		if (namedState === 'refresh') {
			setNamedState('loading');
			refresh();
		}
	}, [refresh, namedState]);

	useEffect(() => {
		if (!loading && namedState === 'loading') {
			setNamedState('idle');
		}
	}, [loading, namedState]);

	if (!isReactGadget) {
		return {
			reinitialise: () => undefined,
			isEditable: null,
			isLoading: null,
		};
	}

	const { componentEdit } = GADGETS_DEFINITION[reactKey];

	return {
		reinitialise,
		isEditable: Boolean(componentEdit),
		isLoading: namedState === 'loading' || (loading && namedState === 'idle'),
	};
};
