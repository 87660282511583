import { FetchError, isClientFetchError } from '@atlassian/jira-fetch';

export type ArgType = {
	error: Error;
	sendSuccessExperience: () => void;
	sendFailedExperience: () => void;
	logErrorEvent: (arg1?: string | undefined) => void;
};

export const NETWORK_ERROR_MESSAGE = 'client network error';
export const UNAUTHENTICATED_ERROR_MESSAGE = 'unauthenticated (401)';

export const handleFetchErrorAsExperience = ({
	error,
	sendSuccessExperience,
	sendFailedExperience,
	logErrorEvent,
}: ArgType) => {
	if (isClientFetchError(error)) {
		sendSuccessExperience();
		logErrorEvent(NETWORK_ERROR_MESSAGE);
	} else if (error instanceof FetchError && error.constructor === FetchError) {
		const errorCode = Number(error.message.substring(error.message.length - 3));

		if (errorCode === 304) {
			// 304 response cached
			sendSuccessExperience();
		} else if (errorCode === 401) {
			// 401 user unauthenticated, neither success nor fail
			logErrorEvent(UNAUTHENTICATED_ERROR_MESSAGE);
		} else {
			sendFailedExperience();
			logErrorEvent(`${errorCode} status code response`);
		}
	} else {
		// in the case of Error, SyntaxError and ValidationError(400);
		sendFailedExperience();
		logErrorEvent();
	}
};
