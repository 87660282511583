import { useCallback, useEffect } from 'react';

export const useDashboardRef = () => {
	useEffect(() => {
		const originalGetDashboard = window.AG?.DashboardManager?.getDashboard;

		return () => {
			window.AG = {
				...window.AG,
				DashboardManager: {
					...window.AG?.DashboardManager,
					getDashboard: originalGetDashboard,
				},
			};
		};
	}, []);

	return useCallback((node: HTMLElement | null) => {
		window.AG = {
			...window.AG,
			DashboardManager: {
				...window.AG?.DashboardManager,
				getDashboard: () => node,
			},
		};
	}, []);
};
