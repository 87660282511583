import React, { useRef } from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import { Pressable } from '@atlaskit/primitives';
import { withConnectHost } from '@atlassian/jira-connect-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { getIsDropdownDefaultOpen } from '../../../../utils/is-dropdown-default-open';
import { ConnectHeaderWrapper } from './styled';

type ConnectHeaderItem = {
	id: string;
	styleClass: string;
	url: string;
	title: string;
};
export type ConnectHeaderProp = {
	items?: ConnectHeaderItem[];
};

export type ConnectHeaderLeftOverProp = {
	items: ConnectHeaderItem[];
};

const ConnectHeaderItemsNew = ({ item }: { item: ConnectHeaderItem }) => {
	const webItemRef = useRef<HTMLElement | null>(null);
	const onWebItemClick = () => {
		withConnectHost((connectHost) => {
			if (webItemRef.current) {
				connectHost.triggerWebItem(webItemRef.current, item.styleClass, item.url);
			}
		});
	};
	return (
		<Button id={item.id} appearance="subtle" onClick={onWebItemClick} ref={webItemRef}>
			{item.title}
		</Button>
	);
};

export const ConnectHeaderItems = ({ item }: { item: ConnectHeaderItem }) =>
	fg('endeavour_dashboard_enghealth_webitem_dialog') ? (
		<ConnectHeaderItemsNew item={item} />
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<Button id={item.id} className={item.styleClass} src={item.url} appearance="subtle">
			{item.title}
		</Button>
	);

const DropdownPressableItem = ({ item }: { item: ConnectHeaderItem }) => {
	const webItemRef = useRef<HTMLElement | null>(null);
	const onWebItemClick = () => {
		withConnectHost((connectHost) => {
			if (webItemRef.current) {
				connectHost.triggerWebItem(webItemRef.current, item.styleClass, item.url);
			}
		});
	};
	return (
		<Pressable
			id={item.id}
			onClick={onWebItemClick}
			ref={(ref) => {
				if (ref) {
					webItemRef.current = ref;
				}
			}}
			backgroundColor="color.background.neutral.subtle"
		>
			{item.title}
		</Pressable>
	);
};

export const ConnectHeaderLeftOver = ({ items }: ConnectHeaderLeftOverProp) => {
	if (items.length === 0) {
		return null;
	}

	return (
		<DropdownMenu
			isLoading={false}
			trigger={({ triggerRef, ...triggerProps }: { triggerRef: React.Ref<HTMLElement> }) => (
				<Button appearance="subtle" spacing="default" {...triggerProps} ref={triggerRef}>
					+{items.length}
				</Button>
			)}
			shouldFlip
			placement="bottom-end"
			testId="dashboard-internal-common.ui.dashboard-content.header.connect-header.dropdown-menu"
			defaultOpen={getIsDropdownDefaultOpen('connect-header')}
		>
			{items.map((item) =>
				fg('endeavour_dashboard_enghealth_webitem_dialog') ? (
					<DropdownItem key={item.id}>
						<DropdownPressableItem item={item} />
					</DropdownItem>
				) : (
					<DropdownItem key={item.id} href={item.url} UNSAFE_shouldDisableRouterLink>
						<div
							id={item.id}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={item.styleClass}
						>
							{item.title}
						</div>
					</DropdownItem>
				),
			)}
		</DropdownMenu>
	);
};

export const ConnectHeader = ({ items }: ConnectHeaderProp) => {
	if (!items) {
		return null;
	}

	return (
		<ConnectHeaderWrapper>
			{items.slice(0, 4).map((item: ConnectHeaderItem) => (
				<ConnectHeaderItems item={item} key={item.id} />
			))}
			<ConnectHeaderLeftOver items={items.slice(4)} />
		</ConnectHeaderWrapper>
	);
};
