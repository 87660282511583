import { useCallback, useRef } from 'react';
import type {
	AddEventHandler,
	TriggerEvent,
	RemoveEventHandlers,
	UseEventPublisherReturn,
} from '@atlassian/jira-dashboard-common';

export const useEventPublisher = (): UseEventPublisherReturn => {
	// Do not reassign current, as this change will not be seen by useCallback dependency list.  Mutate object instead.
	const eventHandlers = useRef(new Map()).current;
	const singleUseEventHandlers = useRef(new Map()).current;

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const addEventHandler = useCallback(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		((eventName, handler, isSingleUse = false) => {
			const handlers = isSingleUse ? singleUseEventHandlers : eventHandlers;

			handlers.has(eventName)
				? handlers.get(eventName)?.add(handler)
				: handlers.set(eventName, new Set([handler]));
		}) as AddEventHandler,
		[eventHandlers, singleUseEventHandlers],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const removeEventHandlers = useCallback(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		((eventName, handler) => {
			if (eventName == null) {
				eventHandlers.clear();
				singleUseEventHandlers.clear();

				return;
			}

			if (!handler) {
				eventHandlers.delete(eventName);
				singleUseEventHandlers.delete(eventName);

				return;
			}

			eventHandlers.get(eventName)?.delete(handler);

			singleUseEventHandlers.get(eventName)?.delete(handler);
		}) as RemoveEventHandlers,
		[eventHandlers, singleUseEventHandlers],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const triggerEvent = useCallback(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		((eventName) => {
			eventHandlers.get(eventName)?.forEach((handler: () => void) => {
				handler();
			});
			singleUseEventHandlers.get(eventName)?.forEach((handler: () => void) => {
				handler();
			});

			singleUseEventHandlers.delete(eventName);
		}) as TriggerEvent,
		[eventHandlers, singleUseEventHandlers],
	);

	return {
		addEventHandler,
		removeEventHandlers,
		triggerEvent,
	};
};
