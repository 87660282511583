import { useCallback, useRef } from 'react';
import memoizeOne from 'memoize-one';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics';
import logger from '@atlassian/jira-common-util-logging/src/log';
import {
	VIEW_WRM_GADGET_EXPERIENCE,
	type GadgetData,
	type GadgetContentType,
	type GadgetMetricsEventType,
} from '@atlassian/jira-dashboard-common';
import { ff } from '@atlassian/jira-feature-flagging';
import getMeta from '@atlassian/jira-get-meta';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { BM3Metric } from '@atlassian/jira-providers-spa-apdex-analytics';
import { afterPaintEmit } from '@atlassian/jira-providers-spa-apdex-analytics/src/after-paint-emit';
import { trackBM3FeatureFlagsAccessed } from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/utils/track-bm3-feature-flags-accessed';
import { useRouter } from '@atlassian/jira-router';
import { useDashboardResource } from '@atlassian/jira-router-resources-dashboard';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller';
import { addUFOCustomData } from '@atlassian/ufo-custom-data';
import { addApdexToAll } from '@atlassian/ufo-interaction-metrics';
import { gadgetLoad, dashboardLoad, getDashboardFeatureFlags } from '../../utils';
import { useRenderAboveTheFold, useDashboardScrollAnalytics } from '../above-the-fold';
import {
	UNKNOWN_GADGET_TYPE,
	CONNECT_MODULE_PATTERN,
	FORGE_MODULE_PATTERN,
	WRM_GADGET_METRICS,
	FORGE_GADGET_METRICS,
	REACT_GADGET_METRICS,
	FALLBACK_GADGET_SOURCE,
	IDLE_GADGET_SOURCE,
	GADGET_MARK_EVENT_PATTERN,
	GADGET_RENDER_CONTAINER_EVENT,
	GADGET_START_EVENT,
	DEFAULT_WRM_GADGET_EVENTS,
} from './constants';
import type {
	GadgetId,
	GadgetLifeCycleRecord,
	GadgetMetricsRecord,
	GadgetMetricsContext,
	MetricsBridgeContextTimeStamps,
} from './gadget-metrics-bridge-types';
import type { CustomMark, GadgetMetricsCustomMarks } from './gadget-metrics-event-analytics-types';

// At time of writing BM3 doesn't support ssrFeatureFlags unless your page performs initial-paint
// in ssr (not just skeleton content), which Dashboard does not.
// We are forced to recreate here based on "@atlassian/jira-browser-metrics".
// We cannot reference code from BM directly since it gets removed in storybook, causing errors.
const getSSRFeatureFlags = memoizeOne(() => {
	try {
		const metaTagValue = getMeta('spa-service-flags');
		return (metaTagValue != null && JSON.parse(metaTagValue)) || {};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return null;
	}
});

const expAttributes = {
	analyticsSource: 'dashboard',
	application: null,
	edition: null,
	additionalAttributes: {},
	wasExperienceSuccesful: true,
} as const;

// All expected metrics events from a WRM gadget in Config mode
const DEFAULT_WRM_GADGET_CONFIG_EVENTS = [GADGET_RENDER_CONTAINER_EVENT];

// Expected metrics events from immediately finished gadgets e.g. fallbacks
const IMMEDIATE_FINISH_GADGET_EVENTS = [GADGET_RENDER_CONTAINER_EVENT];

// Events send by the gadget container, which should not be counted to determine idle gadget
const GADGET_CONTAINER_EVENTS = new Set<string>([GADGET_RENDER_CONTAINER_EVENT]);

export const getAllEventHandlers = (
	eventSource: string,
	contentType: GadgetContentType = 'View',
) => {
	if (
		eventSource === WRM_GADGET_METRICS ||
		eventSource === FORGE_GADGET_METRICS ||
		eventSource === REACT_GADGET_METRICS
	) {
		return contentType === 'Config' ? DEFAULT_WRM_GADGET_CONFIG_EVENTS : DEFAULT_WRM_GADGET_EVENTS;
	}

	return IMMEDIATE_FINISH_GADGET_EVENTS;
};

const isIdle = (gadgetEventRecord?: GadgetLifeCycleRecord): boolean => {
	if (gadgetEventRecord == null) {
		return false;
	}
	if (gadgetEventRecord.source === IDLE_GADGET_SOURCE) {
		return true;
	}
	const eventEntries = Array.from(gadgetEventRecord.events.entries());
	return (
		eventEntries.length === GADGET_CONTAINER_EVENTS.size &&
		eventEntries.every((entry) => GADGET_CONTAINER_EVENTS.has(entry[0]))
	);
};

export const extractGadgetCustomMarks: (
	arg1: Map<GadgetMetricsEventType, GadgetMetricsRecord>,
) => CustomMark[] = (events) => {
	const customMarksMap: Record<string, CustomMark> = {};
	const customMarksList: CustomMark[] = [];
	events.forEach((event, eventKey) => {
		const parts = GADGET_MARK_EVENT_PATTERN.exec(eventKey);
		if (parts == null) {
			return;
		}
		const key = parts[1];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const stage = parts[2] as 'start' | 'end';
		const customMark = customMarksMap[key];
		if (customMark == null) {
			const newCustomMark: CustomMark = {
				name: `gadgetMark_${key}`,
				[stage]: event.spaTime,
			};
			customMarksMap[key] = newCustomMark;
			customMarksList.push(newCustomMark);
		} else {
			customMark[stage] = event.spaTime;
		}
	});
	return customMarksList;
};

const getInitialMarks = (context: GadgetMetricsContext) => ({
	TTI: Date.now() - Number(context.navStart),
	isInitialRender: context.isInitialRender,
	isWritable: context.isWritable,
	isRouteReplaced: context.isRouteReplaced,
	currentPageId: context.currentPageId,
	// TODO should be removed in favour of BM3 pageVisible property
	pageVisible: !window?.document?.hidden,
	ssrFeatureFlags: getSSRFeatureFlags(),
	featureFlags: getDashboardFeatureFlags(),
	centralised: true,
});

export const getNavStart = (
	isInitialRender: boolean,
	lastTransitionStartTime?: number,
	navigationStart?: string,
) => {
	const sessionStart = navigationStart != null ? new Date(navigationStart).getTime() : 0;
	return !isInitialRender && lastTransitionStartTime != null
		? lastTransitionStartTime
		: sessionStart;
};

const isInvalidContextState = (
	metricsContextTimeStamp: MetricsBridgeContextTimeStamps,
	currentNavStart?: number,
	currentPageId?: string,
) => {
	if (metricsContextTimeStamp.currentPageId !== currentPageId) return true;
	if (metricsContextTimeStamp.created < metricsContextTimeStamp.navStart) return true;
	const now = Date.now();
	if (now < metricsContextTimeStamp.navStart) return true;
	if (now < Number(currentNavStart)) return true;
	return false;
};

export const useGadgetMetricsAnalytics = (dashboardId?: string) => {
	const [{ isInitialRender, lastTransitionStartTime, navigationStart, currentPageId = 'unknown' }] =
		useSpaStateTransition();
	const [{ aboveTheFoldGadgets }] = useRenderAboveTheFold();
	const [routerState] = useRouter();

	const navStart = getNavStart(isInitialRender, lastTransitionStartTime, navigationStart);

	const dashboardData = useDashboardResource().data;
	const { scrollStartTimestamp, resetScrollStartTime } = useDashboardScrollAnalytics();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const newContext: GadgetMetricsContext = {
		dashboardId,
		isInitialRender,
		isRouteReplaced: routerState.action === 'REPLACE',
		lastTransitionStartTime,
		navigationStart,
		currentPageId,
		navStart,
		gadgets: dashboardData?.gadgets,
		isWritable: dashboardData?.writable ?? false,
		scrollStartTimestamp,
		aboveTheFoldGadgets,
		resetScrollStartTime,
	};
	const context = useRef(newContext);
	context.current = newContext;

	const sendGadgetMetrics = useCallback(
		(gadgetId: GadgetId, gadgetEventRecord: GadgetLifeCycleRecord) => {
			if (context.current.navigationStart == null) {
				return;
			}

			const gadgetModel =
				context.current.gadgets?.find((item: GadgetData) => item.id === gadgetId) || null;

			// Setup common marks first
			const customMarks: GadgetMetricsCustomMarks = {
				...getInitialMarks(context.current),
				timeout: gadgetEventRecord.timedOut === true,
				gadgetContentType: gadgetEventRecord.contentType,
				isAboveTheFold: context.current.aboveTheFoldGadgets.has(gadgetId),
			};

			// Timeout Marks
			if (gadgetEventRecord.timedOut === true) {
				if (isIdle(gadgetEventRecord)) {
					customMarks.timeout_idle = true;
				} else {
					getAllEventHandlers(gadgetEventRecord.source, gadgetEventRecord.contentType)
						.filter((event) => !GADGET_CONTAINER_EVENTS.has(event))
						.filter((event) => !gadgetEventRecord.events.has(event))
						.forEach((event) => {
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							customMarks[`timeout_${event as string}`] = true;
						});
				}
			}

			if (
				gadgetEventRecord.source === WRM_GADGET_METRICS ||
				gadgetEventRecord.source === FORGE_GADGET_METRICS ||
				gadgetEventRecord.source === REACT_GADGET_METRICS
			) {
				customMarks.gadgetType =
					gadgetModel?.amdModule ||
					gadgetModel?.forge?.key ||
					gadgetModel?.reactKey ||
					UNKNOWN_GADGET_TYPE;
				const renderContainer = gadgetEventRecord.events.get(GADGET_RENDER_CONTAINER_EVENT);
				const start = gadgetEventRecord.events.get(GADGET_START_EVENT);
				if (renderContainer != null) {
					customMarks.gadgetRenderContainer =
						renderContainer.spaTime - Number(context.current.navStart);
				}
				if (start != null) {
					customMarks.gadgetStartTime = start.spaTime - Number(context.current.navStart);
					customMarks.gadgetDuration = Date.now() - start.spaTime;
				}
			} else if (
				gadgetEventRecord.source === IDLE_GADGET_SOURCE ||
				gadgetEventRecord.source === FALLBACK_GADGET_SOURCE
			) {
				// The gadget doesn't send any event till timed out
				customMarks.gadgetType =
					gadgetModel?.amdModule || gadgetModel?.forge?.key || UNKNOWN_GADGET_TYPE;
			}

			extractGadgetCustomMarks(gadgetEventRecord.events).forEach((gadgetMark) => {
				if (gadgetMark.start != null) {
					customMarks[`${gadgetMark.name}-start`] =
						gadgetMark.start - Number(context.current.navStart);
				}
				if (gadgetMark.start != null && gadgetMark.end != null) {
					customMarks[`${gadgetMark.name}-duration`] = gadgetMark.end - gadgetMark.start;
				}
			});

			const gadgetLoadIdentifier = `gadget-${
				context.current.dashboardId ?? 'no-dashboard-id'
			}-${gadgetId}`;
			gadgetLoad(gadgetLoadIdentifier).start({ startTime: context.current.navStart });
			gadgetLoad(gadgetLoadIdentifier).stop({
				customData: customMarks,
				stopTime: Date.now(),
			});

			if (
				gadgetEventRecord.source === WRM_GADGET_METRICS &&
				gadgetEventRecord.contentType !== 'View' &&
				gadgetEventRecord.contentType !== 'Config'
			) {
				sendExperienceAnalytics({
					...expAttributes,
					experience: VIEW_WRM_GADGET_EXPERIENCE,
					wasExperienceSuccesful: false,
				});
			}
		},
		[],
	);

	const sendOverallGadgetMetrics = useCallback(
		(
			gadgetIds: GadgetId[],
			gadgetMetricsEvents: Map<GadgetId, GadgetLifeCycleRecord>,
			timeout: boolean,
		) => {
			const metricToReport = {
				timeout,
				fullDashboardTTI: Date.now() - Number(context.current.navStart),
				dashboardScrollStartTime:
					context.current.scrollStartTimestamp.current != null
						? context.current.scrollStartTimestamp.current - Number(context.current.navStart)
						: 'unknown',
				belowTheFoldTTI:
					Date.now() -
					(context.current.scrollStartTimestamp.current != null &&
					(context.current.gadgets?.length || 0) - context.current.aboveTheFoldGadgets.size > 0
						? context.current.scrollStartTimestamp.current
						: Date.now()),
			};

			fireOperationalAnalytics(createAnalyticsEvent({}), 'fullDashboard loaded', metricToReport);
		},
		[createAnalyticsEvent],
	);

	const onAboveTheFoldGadgetsRender = useCallback(
		(
			gadgetIds: GadgetId[],
			gadgetMetricsEvents: Map<GadgetId, GadgetLifeCycleRecord>,
			gadgetRenderStartTime: number,
			contextTimeStamps: MetricsBridgeContextTimeStamps,
			timeout: boolean,
		) => {
			// count connect gadgets
			const connectGadgetCount =
				context.current.gadgets?.filter((item) => CONNECT_MODULE_PATTERN.test(item.amdModule || ''))
					.length || 0;

			// count forge gadgets
			const forgeGadgetCount =
				context.current.gadgets?.filter((item: GadgetData) =>
					FORGE_MODULE_PATTERN.test(item.forge?.key || ''),
				).length || 0;
			// count untracked gadgets (including connect)
			const untrackedGadgetCount =
				context.current.gadgets?.filter((item: GadgetData) =>
					gadgetIds.every((id) => id !== item.id),
				).length || 0;

			const initialMarks = getInitialMarks(context.current);
			const gadgetRenderStart = gadgetRenderStartTime - Number(context.current.navStart);

			// Validate metrics context
			const invalidContextState =
				isInvalidContextState(
					contextTimeStamps,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					context.current.navStart as number,
					context.current.currentPageId,
				) ||
				initialMarks.TTI < 0 ||
				gadgetRenderStart < 0;

			if (invalidContextState) {
				// Report abnormal status
				logger.safeWarnWithoutCustomerData(
					'spa-apps.dashboard.gadget.metrics',
					`metrics context state is invalid for dashboard [${
						context.current.currentPageId ?? 'null'
					}]: { contextTimestamps: ${JSON?.stringify(
						contextTimeStamps,
					)}, now:${Date.now()}, pnow:${performance?.now()}, navStart:${
						context.current.navStart
					} }`,
				);
			}

			const customData = {
				...initialMarks,
				gadgetRenderStart,
				// counts
				gadgetCount_Total: context.current.gadgets?.length ?? 0,
				gadgetCount_Connect: connectGadgetCount,
				gadgetCount_Forge: forgeGadgetCount,
				gadgetCount_Unknown: untrackedGadgetCount - connectGadgetCount,
				timeout,
				gadgetCount_AboveTheFold: context.current.aboveTheFoldGadgets.size,
				isStaled: invalidContextState,
			};
			addUFOCustomData(customData);

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			trackBM3FeatureFlagsAccessed(dashboardLoad as unknown as BM3Metric);

			addApdexToAll({
				key: dashboardLoad.key,
				stopTime: performance.now(),
			});
			if (ff('bm3.emit-on-raf.top-experiences')) {
				afterPaintEmit(
					(stopTime: number) => {
						dashboardLoad.stop({
							stopTime,
							// @ts-expect-error - feature flags may technically be null which BM3 doesn't like
							customData,
						});
					},
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					dashboardLoad as unknown as BM3Metric,
				);
			} else {
				dashboardLoad.stop({
					// @ts-expect-error - feature flags may technically be null which BM3 doesn't like
					customData,
				});
			}
		},
		[],
	);

	const resetScrollStartMetrics = useCallback(() => {
		context.current.resetScrollStartTime();
	}, []);

	const sendDebuggingAnalytics = useCallback(
		(contextTimeStamps: MetricsBridgeContextTimeStamps) => {
			const attributes = {
				isStale: isInvalidContextState(
					contextTimeStamps,
					context.current.navStart,
					context.current.currentPageId,
				),
				tti: Date.now() - Number(context.current.navStart),
				currentPageId: context.current.currentPageId,
			};
			fireOperationalAnalytics(createAnalyticsEvent({}), 'tti sent', attributes);
		},
		[createAnalyticsEvent],
	);

	return {
		sendGadgetMetrics,
		sendOverallGadgetMetrics,
		onAboveTheFoldGadgetsRender,
		resetScrollStartMetrics,
		sendDebuggingAnalytics,
	};
};
