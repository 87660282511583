import React, { useCallback, useRef } from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorMoreIcon from '@atlaskit/icon/glyph/editor/more';
import { Section } from '@atlaskit/menu';
import { MODAL_ID as DASHBOARD_MODAL_ID } from '@atlassian/jira-dashboard-common';
import { useIntl } from '@atlassian/jira-intl';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { useDropdownItemOnClickHandler } from '../../../../utils';
import { getIsDropdownDefaultOpen } from '../../../../utils/is-dropdown-default-open';
import messages from './messages';
import {
	CopyModal,
	AutomaticRefreshModal,
	DeleteModal,
	RenameOrShareModal,
	ConfigureWallboardModal,
	CreateModal,
} from './modals';
import type { ActionDropdownProps } from './types';
import { WallboardSlideshow } from './wallboard-slideshow';

export const ActionDropdown = ({ data, connectToolMenuItems }: ActionDropdownProps) => {
	const { isAnonymous, atlassianAccountId } = useTenantContext();
	const { dropdownItemClickHandler } = useDropdownItemOnClickHandler();
	const dropdownTriggerButton = useRef<HTMLElement | null>(null);

	const [, { on: modalCopyOpen }] = useSwitchModals(DASHBOARD_MODAL_ID.DASHBOARD_COPY);
	const [, { on: modalAutomaticRefreshOpen }] = useSwitchModals(
		DASHBOARD_MODAL_ID.AUTOMATIC_REFRESH,
	);
	const [, { on: modalRenameAndShareOpen }] = useSwitchModals(
		DASHBOARD_MODAL_ID.DASHBOARD_RENAME_OR_SHARE,
	);
	const [, { on: modalDeleteOpen }] = useSwitchModals(DASHBOARD_MODAL_ID.DASHBOARD_DELETE);
	const [, { on: modalCreateOpen }] = useSwitchModals(DASHBOARD_MODAL_ID.DASHBOARD_CREATE);

	// This is a hack to focus the trigger button after a modal is closed.
	const onModalCloseCallback = useCallback(() => {
		setTimeout(() => {
			if (dropdownTriggerButton.current) {
				dropdownTriggerButton.current.focus();
			}
		}, 1);
	}, [dropdownTriggerButton]);

	const { formatMessage } = useIntl();

	if (!data) return null;

	const dashboardId = data.id;
	const isDashboardOwner = atlassianAccountId === data.owner;
	const viewAsWallboardUrl = `/jira/dashboards/wallboard?dashboardId=${dashboardId}`;

	return (
		<>
			<RenameOrShareModal id={dashboardId} onModalCloseCallback={onModalCloseCallback} />
			<CopyModal id={dashboardId} onModalCloseCallback={onModalCloseCallback} />
			<AutomaticRefreshModal id={dashboardId} onModalCloseCallback={onModalCloseCallback} />
			<DeleteModal id={dashboardId} onModalCloseCallback={onModalCloseCallback} />
			<ConfigureWallboardModal onModalCloseCallback={onModalCloseCallback} />
			<CreateModal onModalCloseCallback={onModalCloseCallback} />
			<DropdownMenu
				isLoading={false}
				trigger={({ triggerRef, ...triggerProps }: { triggerRef: React.Ref<HTMLElement> }) => (
					<Button
						{...triggerProps}
						spacing="default"
						iconBefore={
							<EditorMoreIcon label={formatMessage(messages.moreActions)} size="medium" />
						}
						// To utilise this `ref` for a custom trigger button, only `triggerRef` needs to be the value passed in.
						// However, we've defined a function instead so we can save the ref locally
						// and focus it when modal closes
						ref={(buttonRef) => {
							// This is the default call if only `triggerRef` gets passed in. We still call it.
							if (typeof triggerRef === 'function') {
								triggerRef(buttonRef);
							}
							// This is a hack so we can focus this button after a modal is closed.
							if (dropdownTriggerButton.current === null) {
								dropdownTriggerButton.current = buttonRef;
							}
						}}
					/>
				)}
				shouldFlip
				placement="bottom-end"
				appearance="tall"
				testId="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.dropdown-menu"
				defaultOpen={getIsDropdownDefaultOpen('action-dropdown')}
				shouldRenderToParent
			>
				<Section>
					<DropdownItemGroup title={data.title}>
						{data.writable && (
							<DropdownItem
								data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.rename-and-share"
								onClick={modalRenameAndShareOpen}
							>
								{formatMessage(data.systemDashboard ? messages.share : messages.renameOrShare)}
							</DropdownItem>
						)}
						{!isAnonymous && (
							<DropdownItem
								data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.copy"
								onClick={modalCopyOpen}
							>
								{formatMessage(messages.copy)}
							</DropdownItem>
						)}
						{data.writable && (
							<DropdownItem
								data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.automatic-refresh"
								onClick={modalAutomaticRefreshOpen}
							>
								{formatMessage(messages.automaticRefresh)}
							</DropdownItem>
						)}
						<DropdownItem
							data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.wallboard"
							href={viewAsWallboardUrl}
							onClick={(e) =>
								dropdownItemClickHandler(e, viewAsWallboardUrl, 'dashboard.view-as-wallboard-item')
							}
							UNSAFE_shouldDisableRouterLink
						>
							{formatMessage(messages.wallboard)}
						</DropdownItem>
						{isDashboardOwner && (
							<DropdownItem
								data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.delete"
								onClick={modalDeleteOpen}
							>
								{formatMessage(messages.moveToTrash)}
							</DropdownItem>
						)}
					</DropdownItemGroup>
				</Section>
				<WallboardSlideshow />
				<Section hasSeparator>
					{!isAnonymous && (
						<DropdownItem
							data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.create"
							onClick={modalCreateOpen}
						>
							{formatMessage(messages.create)}
						</DropdownItem>
					)}
					{!isAnonymous && (
						<DropdownItem
							data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.find"
							href="/jira/dashboards"
							UNSAFE_shouldDisableRouterLink
						>
							{formatMessage(messages.find)}
						</DropdownItem>
					)}
				</Section>
				{connectToolMenuItems && connectToolMenuItems.length ? (
					<Section
						testId="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.dashboard-connect-menu-items"
						hasSeparator
					>
						{connectToolMenuItems.map(({ id, title, url, styleClass }) => (
							<DropdownItem key={id} href={url} UNSAFE_shouldDisableRouterLink>
								<div
									id={id}
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className={styleClass}
								>
									{title}
								</div>
							</DropdownItem>
						))}
					</Section>
				) : null}
			</DropdownMenu>
		</>
	);
};
