// eslint-disable-next-line jira/restricted/react-lazy
import React, { lazy, useLayoutEffect, useCallback, useMemo } from 'react';
import type { GadgetMetricsEventType, GadgetContentType } from '@atlassian/jira-dashboard-common';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics';
import { DASHBOARD_GADGET_MODULE, ENTRY_POINT_EDIT } from '@atlassian/jira-forge-ui-constants';
import type {
	DashboardGadgetExtensionData,
	InitialRenderPayload,
} from '@atlassian/jira-forge-ui-types';
import { isCustomUIExtension } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import type { LazyComponentProps, LazyRenderer } from '../../types';
import type { DashboardGadgetView, DashboardGadgetEdit } from './index';

const GADGET_START_EVENT = 'gadget-start';
const GADGET_FINISH_EVENT = 'gadget-rendering-finished';

export type ViewProps = LazyComponentProps<typeof DashboardGadgetView> & {
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void;
};

export type EditProps = LazyComponentProps<typeof DashboardGadgetEdit> & {
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void;
};

// We are using React.lazy() instead of react-loosley-lazy here because DashboardGadget
// renders RendererNext from the forge-ui package which uses Suspense
// and you can't mix React.Suspense with RLL

const getUiKitModule = () =>
	import(/* webpackChunkName: "async-forge-ui-dashboard-gadget" */ './index');

export const LazyDashboardGadgetView: LazyRenderer<typeof DashboardGadgetView> = withMetrics(
	lazy(() => getUiKitModule().then((module) => ({ default: module.DashboardGadgetView }))),
);

export const LazyDashboardGadgetEdit: LazyRenderer<typeof DashboardGadgetEdit> = withMetrics(
	lazy(() => getUiKitModule().then((module) => ({ default: module.DashboardGadgetEdit }))),
);

const getIframeModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-iframe-dashboard-gadget" */ '@atlassian/jira-forge-ui/src/common/ui/iframe/components/dashboard-gadget'
	);

export const LazyDashboardGadgetViewIframe = withMetrics(
	lazy(() => getIframeModule().then((module) => ({ default: module.DashboardGadgetViewRenderer }))),
);

export const LazyDashboardGadgetEditIframe = withMetrics(
	lazy(() => getIframeModule().then((module) => ({ default: module.DashboardGadgetEditRenderer }))),
);

const useDashboardGadget = <ED extends Omit<DashboardGadgetExtensionData, 'type'>>(
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void,
	extensionData: ED,
	entryPoint?: typeof ENTRY_POINT_EDIT,
) => {
	const onInitialRender = useCallback(
		(payload?: InitialRenderPayload) => {
			if (payload !== undefined && payload.error == null) {
				onReportGadgetMetrics(GADGET_FINISH_EVENT, 'View');
			} else {
				onReportGadgetMetrics(GADGET_FINISH_EVENT, 'ErrorMessage');
			}
		},
		[onReportGadgetMetrics],
	);

	useLayoutEffect(() => {
		onReportGadgetMetrics(GADGET_START_EVENT, 'View');
	}, [onReportGadgetMetrics]);

	return useMemo(() => {
		const extraPropsUiCommon = {
			module: DASHBOARD_GADGET_MODULE,
			entryPoint,
			extensionData: {
				...extensionData,
				entryPoint,
				type: DASHBOARD_GADGET_MODULE,
			},
		};
		return { onInitialRender, extraPropsUiCommon };
	}, [entryPoint, extensionData, onInitialRender]);
};

export const AsyncDashboardGadgetView = (props: ViewProps) => {
	const { extensionData, onReportGadgetMetrics, ...restProps } = props;
	const { onInitialRender, extraPropsUiCommon } = useDashboardGadget(
		onReportGadgetMetrics,
		extensionData,
	);
	const extraPropsUiKit = { ...extraPropsUiCommon, onInitialRender };
	const extraPropsCustomUi = { ...extraPropsUiCommon, onLoad: onInitialRender };

	return isCustomUIExtension(props.extension) ? (
		<LazyDashboardGadgetViewIframe {...restProps} {...extraPropsCustomUi} />
	) : (
		<LazyDashboardGadgetView {...restProps} {...extraPropsUiKit} />
	);
};

export const AsyncDashboardGadgetEdit = (props: EditProps) => {
	const { extensionData, onReportGadgetMetrics, ...restProps } = props;
	const { onInitialRender, extraPropsUiCommon } = useDashboardGadget(
		onReportGadgetMetrics,
		extensionData,
		ENTRY_POINT_EDIT,
	);
	const extraPropsUiKitEdit = { ...extraPropsUiCommon, onInitialRender };
	const extraPropsCustomUiEdit = { ...extraPropsUiCommon, onLoad: onInitialRender };

	return isCustomUIExtension(props.extension, ENTRY_POINT_EDIT) ? (
		<LazyDashboardGadgetEditIframe {...restProps} {...extraPropsCustomUiEdit} />
	) : (
		<LazyDashboardGadgetEdit {...restProps} {...extraPropsUiKitEdit} />
	);
};
