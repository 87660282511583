import { createContext, useContext } from 'react';
import defaultMetaGetter from '@atlassian/jira-get-meta';

export type { MetaConsumerData } from './types';

/**
 * Provide `getMeta(...)` via context.
 */
const MetaAPIContext = createContext<typeof defaultMetaGetter>(defaultMetaGetter);

export const { Provider: MetaAPIProvider, Consumer: MetaAPIConsumer } = MetaAPIContext;

/**
 * Return `getMeta` from context.
 */
export const useMetaAPI = (): typeof defaultMetaGetter => useContext(MetaAPIContext);
