export const GROUP_SHARE = 'group' as const;
export const PROJECT_SHARE = 'project' as const;
export const PROJECT_UNKNOWN_SHARE = 'project-unknown' as const;
export const OPEN_SHARE = 'loggedin' as const;
export const PUBLIC_SHARE = 'global' as const;
export const USER_SHARE = 'user' as const;

export type SharePermissionGroup = {
	/**
	 * We are deprecating the use of group names. See https://hello.atlassian.net/wiki/spaces/JCGR/pages/1082623041/Group+Rename+-+Project+Poster
	 * This property won't be removed as it is still needed to show the group name to the user (read paths).
	 * But if possible try using the groupId property to call a mutation related API (write, update or delete operations)
	 * Note: additional backend efforts might be required to add support for groupId in the backend.
	 * @GroupRenameDeprecated the name property is no longer immutable
	 */
	readonly name?: string;
	readonly groupId?: string;
};

export type SharePermissionProject = {
	readonly id: string;
	readonly name: string;
	readonly avatarUrl: string;
};

export type SharePermissionUser = {
	readonly accountId: string;
	readonly displayName: string;
	readonly avatarUrl: string;
};

export type SharePermissionProjectRole = {
	readonly id: number;
	readonly name: string;
};

export type GroupShare = {
	readonly type: typeof GROUP_SHARE;
	readonly group: SharePermissionGroup;
};

export type ProjectShare = {
	readonly type: typeof PROJECT_SHARE;
	readonly project: SharePermissionProject;
	readonly role?: SharePermissionProjectRole;
};

export type ProjectUnknownShare = {
	readonly type: typeof PROJECT_UNKNOWN_SHARE;
};

export type UserShare = {
	readonly type: typeof USER_SHARE;
	readonly user: SharePermissionUser;
};

export type PublicShare = {
	readonly type: typeof PUBLIC_SHARE;
};

export type OpenShare = {
	readonly type: typeof OPEN_SHARE;
};

export type SharePermission =
	| PublicShare
	| OpenShare
	| GroupShare
	| ProjectShare
	| ProjectUnknownShare
	| UserShare; // this is what can come from backend
