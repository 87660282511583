import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import { useInvitePeopleModal } from '@atlassian/invite-people/InvitePeopleModalContext';
import { type FlagProps, useFlagsService } from '@atlassian/jira-flags';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-flag';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { useFetchDashboardConfig } from '../../services';
import messages from './messages';
import { getIsDashboardShareable, useOnce } from './utils';

type AddPeopleButtonProps = {
	dashboardId: string;
};

export const AddPeopleButton = ({ dashboardId }: AddPeopleButtonProps) => {
	const { formatMessage } = useIntl();
	const { cloudId } = useTenantContext();
	const { showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data, fetch } = useFetchDashboardConfig(dashboardId);

	useOnce(() => {
		fetch();
	});

	const addFlags = useCallback(
		({ appearance, ...flagProps }: FlagProps) => {
			if (appearance === 'success' || appearance === 'normal') {
				showFlag((serviceProps) => <SuccessFlag {...flagProps} {...serviceProps} />);
			}
			if (appearance === 'warning') {
				showFlag((serviceProps) => <WarningFlag {...flagProps} {...serviceProps} />);
			}
			if (appearance === 'error') {
				showFlag((serviceProps) => <ErrorFlag {...flagProps} {...serviceProps} />);
			}
		},
		[showFlag],
	);

	const isShareableDashboard = getIsDashboardShareable(data);

	// Users are invited to the current dashboard if dashboard is public or shared with org
	// Else invite the user to the instance instead
	const { protocol, host, pathname } = window.location;
	const continueUrl = isShareableDashboard
		? `${protocol}//${host}${pathname}`
		: `${protocol}//${host}`;

	const { openModal } = useInvitePeopleModal({
		resourceAri: `ari:cloud:jira::site/${cloudId}`,
		source: 'jira_dashboard',
		showFlag: addFlags,
		continueUrl,
	});

	const handleOnClick = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'jiraDashboardAddPeopleModal', {});
		openModal();
	};

	return <Button onClick={handleOnClick}>{formatMessage(messages.addPeople)}</Button>;
};
