import React, { useMemo, useCallback, useState } from 'react';
// eslint-disable-next-line @atlaskit/design-system/no-unsupported-drag-and-drop-libraries
import type { DropResult } from 'react-beautiful-dnd';
import { Grid, GridColumn } from '@atlaskit/page';
import { useDebouncedWindowWidth } from '../../../controllers/debounced-window-width';
import { DimensionsProvider } from '../../../controllers/dimensions';
import { useMaximizedGadget } from '../../../controllers/maximized-gadget';
import { createRendererContext, toColumns } from '../../../utils';
import {
	DegenerateDragDropContext,
	DegenerateDroppable,
	DegenerateDraggable,
} from './degenerate-dnd';
import { ContentWrapper, ContainerWrapper } from './styled';
import type { ColumnLayoutProps, GadgetRenderProps, PlaceholderRenderProps } from './types';
import { getColumnWidth } from './utils';

const {
	createRenderFunction: createPlaceholderRender,
	Provider: ColumnPlaceholderContextProvider,
	Consumer: ColumnPlaceholderContextConsumer,
} = createRendererContext<PlaceholderRenderProps>();
export { ColumnPlaceholderContextProvider, ColumnPlaceholderContextConsumer };

const {
	createRenderFunction: createGadgetRender,
	Provider: GadgetLayoutContextProvider,
	Consumer: GadgetLayoutContextConsumer,
} = createRendererContext<GadgetRenderProps>();
export { GadgetLayoutContextProvider, GadgetLayoutContextConsumer };

export const ColumnLayout = ({
	data,
	dnd,
	columnPlaceholder, // placeholder for a column when it is empty (no gadgets in the column)
	layoutPlaceholder, // placeholder for the whole layout when dashboard is empty (no gadgets in all columns)
	onLayoutReposition,
	children,
}: ColumnLayoutProps) => {
	const { layout, writable, gadgets } = data;
	const {
		DragDropContext = DegenerateDragDropContext,
		Draggable = DegenerateDraggable,
		Droppable = DegenerateDroppable,
	} = dnd ?? {};

	const { getPropsByGadgetId, getPropsByColumnIndex } = useMaximizedGadget(gadgets);
	const [isAnyDragging, setIsAnyDragging] = useState<boolean>(false);

	const columns = useMemo(() => toColumns(gadgets, layout), [gadgets, layout]);

	const width = useDebouncedWindowWidth();

	const onDragEnd = useCallback<(arg1: DropResult) => void>(
		(result) => {
			if (!writable || !onLayoutReposition) {
				return;
			}
			const { destination, source } = result;

			if (destination && destination.droppableId != null) {
				const moved = columns[+source.droppableId].splice(source.index, 1)[0];
				columns[+destination.droppableId].splice(destination.index, 0, moved);
				moved.column = +destination.droppableId;
			}
			onLayoutReposition(layout, columns);
			setIsAnyDragging(false);
		},
		[columns, layout, writable, onLayoutReposition],
	);

	const onDragStart = useCallback<() => void>(() => {
		setIsAnyDragging(true);
	}, []);

	const renderColumnPlaceholder = createPlaceholderRender(columnPlaceholder);
	const renderGadget = createGadgetRender(children);

	if (!gadgets.length && layoutPlaceholder != null) {
		return layoutPlaceholder;
	}

	return (
		<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
			<ContainerWrapper data-testid="dashboard-internal-common.ui.dashboard-content.layout.container-wrapper">
				<DimensionsProvider value={`${layout}-${width}`}>
					<Grid layout="fluid">
						{columns.map((items, columnIndex) => {
							const columnWidth = getColumnWidth(
								columnIndex,
								layout,
								getPropsByColumnIndex(columnIndex).display,
							);
							return (
								<GridColumn
									key={columnIndex}
									medium={columnWidth}
									testId="dashboard-internal-common.ui.dashboard-content.layout.column"
								>
									<Droppable droppableId={columnIndex.toString()}>
										{(
											{ innerRef: columnRef, droppableProps, placeholder: dndPlaceholder },
											{ isDraggingOver },
										) => {
											const isNonEmptyCol = items.length > 0;

											return (
												<ContentWrapper
													{...droppableProps}
													ref={columnRef}
													isDimmed={isNonEmptyCol && isDraggingOver}
													isHidden={columnWidth === 0}
												>
													{isNonEmptyCol
														? items.map((gadgetData, rowIndex) => {
																const { isDraggable: isMaybeDraggable, ...gadgetProps } =
																	getPropsByGadgetId(gadgetData.id);
																const isDraggable = dnd != null && isMaybeDraggable;

																return (
																	<Draggable
																		key={gadgetData.id}
																		draggableId={gadgetData.id}
																		index={rowIndex}
																		isDragDisabled={!isDraggable}
																	>
																		{(
																			{ innerRef: rowRef, dragHandleProps, draggableProps },
																			{ isDragging },
																		) => (
																			<div {...draggableProps} ref={rowRef}>
																				{renderGadget({
																					data: gadgetData,
																					dragHandleProps,
																					isDraggable,
																					isDragging,
																					isAnyDragging,
																					...gadgetProps,
																				})}
																			</div>
																		)}
																	</Draggable>
																);
															})
														: renderColumnPlaceholder({
																columnIndex,
																isDraggingOver,
															})}
													{dndPlaceholder}
												</ContentWrapper>
											);
										}}
									</Droppable>
								</GridColumn>
							);
						})}
					</Grid>
				</DimensionsProvider>
			</ContainerWrapper>
		</DragDropContext>
	);
};
