import type { ItemProperties } from '@atlassian/jira-dashboard-user-preference';
import { createRendererContext, type TitleSetter, type DefaultTitleSetter } from '../../utils';

export type GadgetControllerContextType = {
	isDisplayed: boolean;
	isMaximized: boolean;
	isMinimized: boolean;
	onMinimizeOrRestore: (arg1: boolean) => void;
	renderKey: string;
	shouldUseCache: boolean;
	onRefresh: () => void;
	isLoading: boolean;
	setIsLoading: (arg1: boolean) => void;
	lastRefreshed: number;
	isUnconfigured: boolean;
	isEditModeEnabled: boolean;
	isEditButtonDisabled: boolean;
	isInEditMode: boolean;
	isConnectDisabled: boolean;
	onEditModeTrigger: () => void;
	onEditModeCancel: () => void;
	isRenamable: boolean;
	isRenaming: boolean;
	title: string;
	setDefaultTitle: DefaultTitleSetter;
	onRenameTrigger: () => void;
	onRenameSave: TitleSetter;
	onRenameCancel: () => void;
	setPreferences: (arg1: ItemProperties, arg2?: () => void) => Promise<void> | null;
};

const {
	createUseContextField: withGadgetControllerContextField,
	Provider: GadgetControllerContextProvider,
	Consumer: GadgetControllerContextConsumer,
} = createRendererContext<GadgetControllerContextType>();

export {
	withGadgetControllerContextField,
	GadgetControllerContextProvider,
	GadgetControllerContextConsumer,
};

export const useGadgetRefresh = withGadgetControllerContextField('onRefresh');
export const useGadgetRenderKey = withGadgetControllerContextField('renderKey');
export const useIsConnectDisabled = withGadgetControllerContextField('isConnectDisabled');
export const useGadgetSetPreferences = withGadgetControllerContextField('setPreferences');
