import React, { type ReactNode, type ReactElement } from 'react';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing';
import type {
	VIEW_DASHBOARD_EDITABLE_EXPERIENCE as ViewDashboardEditableExperienceType,
	VIEW_DASHBOARD_READONLY_EXPERIENCE as ViewDashboardReadonlyExperienceType,
	VIEW_DASHBOARD_READONLY_EMBED_EXPERIENCE as ViewDashboardReadonlyEmbedExperienceType,
	VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE as ViewDashboardWallboardExperienceType,
	DASHBOARD_VIEW_SOURCENAME as DashboardViewSourceNameType,
	DASHBOARD_VIEW_EMBED_SOURCENAME as DashboardViewEmbedSourceNameType,
	DASHBOARD_EDIT_SOURCENAME as DashboardEditSourceNameType,
	DASHBOARD_WALLBOARD_VIEW_SOURCENAME as DashboardWallboardsourcenameType,
	SYSTEM_DASHBOARD_EDIT_SOURCENAME as SystemDashboardEditSourceNameType,
} from '@atlassian/jira-dashboard-common';
import { DocumentTitle } from '@atlassian/jira-global-document-title';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { UseResourceHookResponse } from '@atlassian/jira-router';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater';
import { OnboardingCheck } from '@atlassian/jira-spa-apps-common/src/interceptors';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { PrefetchLinks } from '../../common';
import { useDashboardRef } from '../../controllers/dashboard-ref';
import { PageLayout } from './styled';

export interface Props {
	analyticsSourceName:
		| typeof DashboardViewSourceNameType
		| typeof DashboardViewEmbedSourceNameType
		| typeof DashboardEditSourceNameType
		| typeof SystemDashboardEditSourceNameType
		| typeof DashboardWallboardsourcenameType;
	analyticsExperience:
		| typeof ViewDashboardReadonlyExperienceType
		| typeof ViewDashboardReadonlyEmbedExperienceType
		| typeof ViewDashboardEditableExperienceType
		| typeof ViewDashboardWallboardExperienceType;
	children: ReactNode;
	unauthenticatedFallback: ReactElement;
	unauthorisedFallback?: ReactElement;
	documentTitle: string;
	resourceData: Pick<
		UseResourceHookResponse<{ id: string; writable?: boolean; title: string }>,
		'data' | 'loading' | 'error'
	>;
}

/**
 * Only pass in unauthorisedFallback prop when this dashboard page
 * needs edit permissions to access
 */
export const DashboardPageContainer = ({
	children,
	analyticsSourceName,
	analyticsExperience,
	unauthenticatedFallback,
	unauthorisedFallback,
	documentTitle,
	resourceData,
}: Props) => {
	const tenantContext = useTenantContext();
	const { data, loading } = resourceData;
	const [{ isInitialRender }] = useSpaStateTransition();

	const ref = useDashboardRef();

	if (!tenantContext) {
		return null;
	}

	if (!tenantContext.atlassianAccountId && !loading && !data) {
		return unauthenticatedFallback;
	}

	if (unauthorisedFallback != null && data && !data.writable) {
		return unauthorisedFallback;
	}

	/**
	 * we need to also mark this with the 'dashboardScreen' analytics source.
	 * Otherwise, the source will default to 'navigationNextScreen'.
	 */
	return (
		<OnboardingCheck>
			<ContextualAnalyticsData
				sourceName={analyticsSourceName}
				sourceType={SCREEN}
				containerId={data?.id}
			>
				<DocumentTitle title={documentTitle} />
				<SubProductUpdater subProduct="platform" />

				<PageLayout ref={ref}>
					{!isInitialRender && <PrefetchLinks dashboardId={data?.id ?? 'pending'} />}
					<div
						id="dashboard"
						// Need className for some of some gadgets, e.g. Filter Count
						// Please always make sure the wrapper dom id and class are in the html response
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="dashboard"
						data-testid="dashboard-internal-common.ui.container.div"
					>
						<ViewExperienceTrackingProvider
							experienceId={data?.id}
							experience={analyticsExperience}
							analyticsSource="dashboard"
							edition={null}
							application={null}
						>
							{children}
						</ViewExperienceTrackingProvider>
					</div>
				</PageLayout>
			</ContextualAnalyticsData>
		</OnboardingCheck>
	);
};
