import React, { useCallback, Fragment, type ReactNode, type ReactElement } from 'react';
import { GADGET_DEFAULT_HEIGHT, type GadgetData } from '@atlassian/jira-dashboard-common';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { SkeletonGadgetContent } from '../../../common/gadget/gadget-skeleton';
import { useShouldRenderGadget } from '../../../controllers/above-the-fold';
import { useGadgetRenderKey } from '../../../controllers/gadget';

export type GadgetBoundarySkeletonAnalyticsProps = {
	isError: boolean;
	errorFallback: ReactElement;
	customPlaceholder?: ReactNode;
	data: GadgetData;
	children: ReactNode;
};

export const GadgetBoundary = ({
	data,
	errorFallback,
	customPlaceholder,
	isError,
	children,
}: GadgetBoundarySkeletonAnalyticsProps) => {
	const { id } = data;

	const renderKey = useGadgetRenderKey();
	const shouldRender = useShouldRenderGadget(id);

	const renderFallback = useCallback<(arg1: { error: Error }) => ReactElement>(
		() => errorFallback,
		[errorFallback],
	);

	return (
		<JSErrorBoundary
			id="gadget-content"
			packageName="jiraDashboardInternalCommon"
			fallback={renderFallback}
		>
			{(() => {
				if (isError) {
					return errorFallback;
				}
				if (customPlaceholder) {
					return customPlaceholder;
				}
				if (!shouldRender) {
					return <SkeletonGadgetContent customHeight={`${GADGET_DEFAULT_HEIGHT}px`} isPulsing />;
				}
				return <Fragment key={renderKey}>{children}</Fragment>;
			})()}
		</JSErrorBoundary>
	);
};
