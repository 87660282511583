import { useState, useEffect } from 'react';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fetchJson } from '@atlassian/jira-fetch';
import type { ConnectMenuItemsResponse } from './types';

export const useConnectMenuItems = (dashboardId: string): ConnectMenuItemsResponse => {
	const [connectMenuItems, setConnectMenuItems] = useState<ConnectMenuItemsResponse>({
		headerItems: [],
		toolItems: [],
	});

	useEffect(() => {
		// TODO - remove this as types say it should not be necessary
		if (dashboardId == null) return;

		(async () => {
			const resourceUrl = `/rest/dashboards/1.0/connect/menuItems/${dashboardId}`;
			try {
				const response = await fetchJson(resourceUrl);
				setConnectMenuItems(response);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'fetchConnectMenuItems',
						packageName: 'jiraDashboardInternalCommon',
						teamName: 'endeavour',
					},
				});
			}
		})();
	}, [dashboardId]);

	return connectMenuItems;
};
