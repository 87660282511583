import React, { useEffect, useRef, type ReactNode } from 'react';
import type { GadgetData } from '@atlassian/jira-dashboard-common';
import { useDashboardAnalytics } from '../../../controllers/dashboard-analytics';
import { getGadgetAnalyticsProps } from '../../../utils';

export const MAX_HEADER_LENGTH = 4840;

export type GadgetAnalyticsWrapperProps = {
	data: GadgetData | null;
	children: ReactNode;
};

export const GadgetAnalyticsContainer = ({ data, children }: GadgetAnalyticsWrapperProps) => {
	const fireAnalytics = useDashboardAnalytics();

	const eventSent = useRef(false);

	useEffect(() => {
		if (data !== null && eventSent.current === false) {
			fireAnalytics({
				...getGadgetAnalyticsProps(data),
				action: 'viewed',
			});
			eventSent.current = true;
		}
	}, [data, fireAnalytics]);

	if (!data) {
		return null;
	}

	if (data.renderedGadgetUrl != null && data.renderedGadgetUrl.length > MAX_HEADER_LENGTH) {
		if (eventSent.current === false) {
			fireAnalytics({
				...getGadgetAnalyticsProps(data),
				action: 'viewedFailed',
			});
			eventSent.current = true;
		}
		throw new Error('Request header is too large');
	}

	return <>{children}</>;
};
