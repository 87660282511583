import React, { Component } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { PositionType } from '@atlaskit/tooltip';
import FavouriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src';
import {
	type ItemType,
	type FavouriteChangeContextType,
	VALID_ITEM_TYPES,
} from '@atlassian/jira-favourite-change-provider/src';
import { ff } from '@atlassian/jira-feature-flagging';
import { getFavourite } from '../services/get-favourite';

export type OwnProps = {
	// This describes the isFavouriteInitial prop
	isFavouriteInitial?: boolean | undefined;
	favouriteItemName?: string | undefined;
	// optional prop, for small star icon
	isSmall?: boolean;
	isShownInList?: boolean;
	baseUrl: string;
	itemId: string;
	itemType: ItemType;
	hideTooltip?: boolean;
	tooltipPosition?: PositionType;
	onClick?: () => void;
};

export type Props = OwnProps & {
	favouriteChangeContext: FavouriteChangeContextType;
};

type State = {
	isFavourite: boolean | undefined;
};

const isProduction = window.BUILD_KEY !== 'JF-TEST';

// eslint-disable-next-line jira/react/no-class-components
export default class FavouriteButton extends Component<Props, State> {
	static defaultProps = {
		isFavouriteInitial: undefined,
		favouriteItemName: undefined,
		isSmall: false,
		hideTooltip: false,
		onClick: noop,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isFavourite: props.isFavouriteInitial,
		};
	}

	componentDidMount() {
		const { baseUrl, itemId, itemType } = this.props;
		if (
			typeof baseUrl === 'undefined' ||
			typeof itemId === 'undefined' ||
			typeof itemType === 'undefined'
		) {
			return;
		}

		// Adding an Error here for the next dev to implement this button.
		// This error won't show in prod, but will show when running a fragment.
		if (!isProduction && !VALID_ITEM_TYPES.includes(itemType)) {
			throw new Error(
				`FavouriteButton: Invalid value for prop 'type'.\n You passed: "${itemType}"\n It must be one of [${JSON.stringify(
					VALID_ITEM_TYPES,
				)}]\n\n`,
			);
		}

		if (typeof this.props.isFavouriteInitial !== 'undefined') {
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
			this.props.favouriteChangeContext.items[itemType][itemId] = {
				id: itemId,
				type: itemType,
				value: this.props.isFavouriteInitial,
				pending: false,
			};
		}

		if (this.getIsFavourite() === undefined) {
			getFavourite(baseUrl, {
				id: itemId,
				type: itemType,
			}).then((isFavourite) => {
				this.setState({
					isFavourite,
				});
			});
		}
	}

	onClick = (analyticsEvent: UIAnalyticsEvent) => {
		const { favouriteChangeContext, itemId, itemType, onClick } = this.props;

		const { changeFavourite } = favouriteChangeContext;
		if (changeFavourite) {
			changeFavourite(
				{
					id: itemId,
					type: itemType,
					value: !this.getIsFavourite(),
				},
				analyticsEvent,
			);
		}

		if (typeof onClick === 'function') onClick();
	};

	getIsFavourite() {
		const { favouriteChangeContext, itemId, itemType } = this.props;

		let itemChanged;
		if (
			typeof favouriteChangeContext.items[itemType] !== 'undefined' &&
			// @ts-expect-error - TS2532 - TS doesn't refine types with index access in this version.
			typeof favouriteChangeContext.items[itemType][itemId] !== 'undefined'
		) {
			// @ts-expect-error - TS2532 - TS doesn't refine types with index access in this version.
			itemChanged = favouriteChangeContext.items[itemType][itemId];
		}

		return itemChanged ? itemChanged.value : this.state.isFavourite;
	}

	getIsPending() {
		const { favouriteChangeContext, itemId, itemType } = this.props;
		let itemChanged;

		if (
			typeof favouriteChangeContext.items[itemType] !== 'undefined' &&
			// @ts-expect-error - TS2532 - TS doesn't refine types with index access in this version.
			typeof favouriteChangeContext.items[itemType][itemId] !== 'undefined'
		) {
			// @ts-expect-error - TS2532 - TS doesn't refine types with index access in this version.
			itemChanged = favouriteChangeContext.items[itemType][itemId];
		}

		return itemChanged ? itemChanged.pending : false;
	}

	render() {
		return (
			<div
				{...(ff('favourite-button-label-and-testid_of2ts')
					? {
							'data-testid': 'favourite-button',
						}
					: {
							'data-test-id': 'favourite-button',
						})}
			>
				<FavouriteButtonStateless
					isFavourite={this.getIsFavourite()}
					favouriteItemName={this.props.favouriteItemName}
					pending={this.getIsPending()}
					hideTooltip={this.props.hideTooltip}
					tooltipPosition={this.props.tooltipPosition}
					onClick={this.onClick}
					isShownInList={this.props.isShownInList}
					isSmall={this.props.isSmall}
				/>
			</div>
		);
	}
}
