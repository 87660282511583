import React from 'react';
import getLocation from '@atlassian/jira-common-location';
import FullPageRedirect from '../full-page-redirect';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const { href = null } = getLocation() || {};
	if (href && !href.includes('login.jsp')) {
		const encodedRedirectUrl = encodeURIComponent(href);
		return <FullPageRedirect url={`/login.jsp?os_destination=${encodedRedirectUrl}`} />;
	}
	return null;
};
