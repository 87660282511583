import React from 'react';
import Blanket from '@atlaskit/blanket';
import type {
	DashboardOperationData,
	DashboardOperationResult,
} from '@atlassian/jira-common-directory-v2/src/model/dashboard-operations';
import AsyncCreateDashboardModal from '@atlassian/jira-create-dashboard-modal/async';
import { MODAL_ID } from '@atlassian/jira-dashboard-common';
import { CREATE_DASHBOARD_EXPERIENCE } from '@atlassian/jira-dashboard-common/src/constants';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { useRouterActions } from '@atlassian/jira-router';
import { useDashboardResource } from '@atlassian/jira-router-resources-dashboard';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';

export const CreateModalInner = ({
	onModalCloseCallback,
}: {
	onModalCloseCallback?: () => void;
}) => {
	const [isOpen, { off: close }] = useSwitchModals(MODAL_ID.DASHBOARD_CREATE);
	const { clear } = useDashboardResource();
	const { push } = useRouterActions();

	const onSuccess = (_: DashboardOperationData, operationResult: DashboardOperationResult) => {
		clear();
		push(`/jira/dashboards/${operationResult.id}/edit`);
		close();
	};

	const onCancel = () => {
		close();
		onModalCloseCallback?.();
	};

	return isOpen ? (
		<Placeholder name="dashboards-modal" fallback={<Blanket />}>
			<AsyncCreateDashboardModal onSuccess={onSuccess} onCancel={onCancel} />
		</Placeholder>
	) : null;
};

export const CreateModal = (props: { onModalCloseCallback?: () => void }) => (
	<JSErrorBoundary
		id={CREATE_DASHBOARD_EXPERIENCE}
		packageName="dashboard"
		fallback="flag"
		attributes={{ task: CREATE_DASHBOARD_EXPERIENCE }}
		withExperienceTracker
	>
		<CreateModalInner {...props} />
	</JSErrorBoundary>
);
