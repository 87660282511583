import React, { useEffect, useState } from 'react';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import { Flex, xcss, Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { formatDistanceStrictWithLocale } from '@atlassian/jira-platform-utils-date-fns';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import messages from './messages';

type LastRefreshedProps = {
	lastRefreshed: number;
	updateInterval: number;
};

export const LastRefreshed = ({ lastRefreshed, updateInterval }: LastRefreshedProps) => {
	const [lastRefreshedTimeAgo, setLastRefreshedTimeAgo] = useState<string>('');
	const { formatMessage } = useIntl();
	const { locale } = useTenantContext();

	useEffect(() => {
		setLastRefreshedTimeAgo(formatMessage(messages.justNow));
		const refreshInterval = setInterval(
			() =>
				lastRefreshed != null &&
				setLastRefreshedTimeAgo(
					formatMessage(messages.timeAgo, {
						timeAgo: formatDistanceStrictWithLocale(lastRefreshed, new Date(), locale),
					}),
				),
			updateInterval,
		);
		return () => clearInterval(refreshInterval);
	}, [formatMessage, lastRefreshed, locale, updateInterval]);

	return (
		<Flex direction="row" alignItems="center" xcss={lastRefreshedWrapperStyles}>
			<RefreshIcon size="small" label={formatMessage(messages.lastRefreshedIcon)} />
			<Text size="UNSAFE_small" color="color.text.subtle">
				{lastRefreshedTimeAgo}
			</Text>
		</Flex>
	);
};

const lastRefreshedWrapperStyles = xcss({
	paddingTop: 'space.200',
	color: 'color.text.subtle',
	gap: 'space.050',
});
