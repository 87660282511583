import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout';
import { FOOTER_MARGIN, DASHBOARD_HEADER_HEIGHT } from '@atlassian/jira-dashboard-common';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyDashboardPlaceholderContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${DASHBOARD_HEADER_HEIGHT + FOOTER_MARGIN}px)`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyDashboardPlaceholderContentContainer = styled.div({
	display: 'flex',
	width: '680px',
	maxWidth: '90%',
	alignItems: 'center',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyDashboardPlaceholderText = styled.p({
	paddingTop: token('space.200', '16px'),
	margin: 0,
	textAlign: 'center',
});
