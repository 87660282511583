import type { ItemType } from '@atlassian/jira-favourite-change-provider/src';
import { performGetRequest } from '@atlassian/jira-fetch';

type Request = {
	id: string;
	type: ItemType;
};

export const getFavouriteUrl = (baseUrl: string, request: Request): string =>
	`${baseUrl}/rest/internal/2/favourites/${request.type}/${request.id}`;

export const getFavourite = async (baseUrl: string, request: Request): Promise<boolean> =>
	performGetRequest(getFavouriteUrl(baseUrl, request));
