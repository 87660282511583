import React, {
	createContext,
	memo,
	type Context as ContextType,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ReactNode,
	type ReactElement,
	type Provider,
	type Consumer,
	useContext,
} from 'react';
import isShallowEqual from '@atlassian/jira-common-util-is-shallow-equal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ChangedBitsLookup<T extends Record<string, any>> = Record<keyof T, number>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RenderFunctionOrNode<T extends Record<string, any>> =
	| ((arg1: T) => ReactNode)
	| ReactNode
	| null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createRendererContext = <T extends Record<string, any>>(): {
	_UnmemoisedContext: ContextType<T | undefined>;
	Provider: Provider<T>;
	Consumer: Consumer<T>;
	createRenderFunction: (children: RenderFunctionOrNode<T>) => (value: T) => ReactElement | null;
	createUseContextField: <K extends keyof T, R = T[K]>(key: K) => () => R;
} => {
	const _UnmemoisedContext = createContext<T | undefined>(undefined);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { Provider: UnmemoisedProvider, Consumer } = _UnmemoisedContext as React.Context<T>;

	// by default the provider is memoised but we still expose the un-memoised provider for testing
	type ProviderProps = JSX.LibraryManagedAttributes<
		typeof UnmemoisedProvider,
		ComponentProps<typeof UnmemoisedProvider>
	>;
	const Provider = memo<ProviderProps>(
		UnmemoisedProvider,
		(
			{ value: valueA, ...propsA }: ProviderProps,
			{ value: valueB, ...propsB }: ProviderProps,
		): boolean => isShallowEqual(valueA, valueB) && isShallowEqual(propsA, propsB),
	);

	const createRenderFunction =
		(children: RenderFunctionOrNode<T>) =>
		(value: T): ReactElement | null =>
			children != null ? (
				<Provider value={value}>
					{typeof children === 'function' ? (
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						<Consumer>{children as (arg1: T) => ReactNode}</Consumer>
					) : (
						children
					)}
				</Provider>
			) : null;

	const createUseContextField =
		<K extends keyof T, R = T[K]>(key: K) =>
		(): R => {
			const value = useContext(_UnmemoisedContext);
			if (value == null) {
				throw new Error('context not found');
			}
			return value[key];
		};

	return {
		_UnmemoisedContext,
		Provider,
		Consumer,
		createRenderFunction,
		createUseContextField,
	};
};
