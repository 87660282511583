import { useMemo } from 'react';
import { type GadgetData, isGadgetConfigured } from '@atlassian/jira-dashboard-common';

export const useConfigurationStatus = ({
	configurable,
	hasNonHiddenUserPrefs,
	renderedGadgetUrl,
	userPrefs,
}: Pick<
	GadgetData,
	'configurable' | 'hasNonHiddenUserPrefs' | 'renderedGadgetUrl' | 'userPrefs'
>) => {
	const isConfigurable = Boolean(configurable || hasNonHiddenUserPrefs || renderedGadgetUrl);
	const isUnconfigured = useMemo(
		() => isConfigurable && !isGadgetConfigured(userPrefs),
		[isConfigurable, userPrefs],
	);

	return { isConfigurable, isUnconfigured };
};
