import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedWindowWidth = (ms = 100) => {
	const [width, setWidth] = useState(window.innerWidth);
	const debounced = useRef(debounce((w: number) => setWidth(w), ms));

	useEffect(() => {
		const handleResize = () => {
			const { innerWidth } = window;

			if (innerWidth !== width) {
				debounced.current(innerWidth);
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [width]);

	return width;
};
