import React, { type ReactElement, useEffect, useRef, type ReactNode, useState } from 'react';
// eslint-disable-next-line jira/restricted/styled-components, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { ThemeProvider } from 'styled-components';
import {
	ViewExperienceSuccessTracker,
	ViewExperienceFailureTracker,
} from '@atlassian/jira-common-experience-tracking-viewing';
import { log as logger } from '@atlassian/jira-common-util-logging';
import type {
	VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE,
	VIEW_DASHBOARD_EDITABLE_EXPERIENCE,
	VIEW_DASHBOARD_READONLY_EXPERIENCE,
	VIEW_DASHBOARD_READONLY_EMBED_EXPERIENCE,
	DashboardConfigType,
	SaveCustomGadgetTitle,
	DashboardConfigAndWRMResourceData,
} from '@atlassian/jira-dashboard-common';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages';
import { SCREEN_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import type { UseResourceHookResponse } from '@atlassian/jira-router';
import { useDashboardAMDModulesResource } from '@atlassian/jira-router-resources-dashboard';
import { GenericLoadingView } from '../../common';
import { useConnectApi } from '../../controllers/connect-api';
import { useDashboardAnalytics } from '../../controllers/dashboard-analytics';
import { GadgetAdditionalStateContainer } from '../../controllers/gadget-additional-state';
import { useGadgetMetricsBridge } from '../../controllers/metrics';
import { useSpaStatePageReady } from '../../controllers/spa-state-page-ready';
import { addDashboardToRecents } from '../../services';
import { AboveTheFoldContainer } from './above-the-fold';
import { AmdGadgetMonolithOverrideStyles } from './amd-gadget-monolith-override-styles';
import { DASHBOARD_PERMISSION_ERROR_CODE_LIST } from './constants';
import { usePageStatusLogger } from './page-status-logger';
import { RouteResourceValidator } from './validate';

const HALF_HOUR = 1000 * 60 * 30;

export const CriticalSideEffects = ({
	data: { id, gadgets, layout },
	saveCustomGadgetTitle,
}: {
	data: Pick<DashboardConfigType, 'id' | 'gadgets' | 'layout'>;
	saveCustomGadgetTitle: SaveCustomGadgetTitle;
}) => {
	useSpaStatePageReady();
	usePageStatusLogger(HALF_HOUR);
	useGadgetMetricsBridge(id, gadgets, layout);
	useConnectApi({ saveCustomGadgetTitle });
	return null;
};

interface Props {
	children: ReactNode;
	customHeight?: string;
	analyticsExperience:
		| typeof VIEW_DASHBOARD_READONLY_EXPERIENCE
		| typeof VIEW_DASHBOARD_READONLY_EMBED_EXPERIENCE
		| typeof VIEW_DASHBOARD_EDITABLE_EXPERIENCE
		| typeof VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE;
	saveCustomGadgetTitle?: SaveCustomGadgetTitle;
	resourceData: Pick<
		UseResourceHookResponse<DashboardConfigAndWRMResourceData>,
		'data' | 'error' | 'loading'
	>;
	permissionErrorFallback: ReactElement;
}

const AMD_RESOURCE_RETRY_LIMIT = 2;

export const DashboardContentContainer = ({
	children,
	customHeight,
	analyticsExperience,
	saveCustomGadgetTitle = () => Promise.resolve(),
	resourceData,
	permissionErrorFallback,
}: Props) => {
	const { data: configData, error: configError, loading: configLoading } = resourceData;
	const {
		loading: amdLoading,
		data: amdData,
		error: amdError,
		refresh: amdRefresh,
	} = useDashboardAMDModulesResource();
	const [amdResourceRetries, setAmdResourceRetries] = useState<number>(0);
	const fireAnalytics = useDashboardAnalytics();
	const eventSent = useRef<unknown>(null);

	const isAmdResourceUnhealthy =
		(configData != null && amdData == null && amdError != null) ||
		(amdData?.amdModules != null && Object.values(amdData?.amdModules).some((v) => v == null));
	const isAmdResourceRetriesExceeded =
		!amdLoading && amdResourceRetries >= AMD_RESOURCE_RETRY_LIMIT;

	useEffect(() => {
		if (
			__SERVER__ ||
			configLoading ||
			configData == null ||
			eventSent.current === Number(configData.id)
		) {
			return;
		}
		fireAnalytics({
			eventType: SCREEN_EVENT_TYPE,
			actionSubjectId: 'dashboard',
			actionSubject: 'dashboardScreen',
			action: 'viewed',
		});
		addDashboardToRecents(configData.id);
		eventSent.current = Number(configData.id);
	}, [configData, fireAnalytics, configLoading]);

	useEffect(() => {
		if (__SERVER__) {
			return;
		}

		if (amdLoading) {
			// NOTE - reset retries when resource changes
			!isAmdResourceUnhealthy && setAmdResourceRetries(0);
			return;
		}

		if (!isAmdResourceUnhealthy) {
			logger.safeInfoWithoutCustomerData(
				'spa-apps.dashboard.content-container',
				`AMD resource loaded, after retries [${amdResourceRetries}]`,
			);
		} else if (isAmdResourceRetriesExceeded) {
			const [failedCount, totalCount] = Object.values(amdData?.amdModules ?? []).reduce(
				([f], v, i, arr) => [f + (v == null ? 1 : 0), arr.length],
				[0, 0],
			);
			logger.safeErrorWithoutCustomerData(
				'spa-apps.dashboard.content-container',
				amdError
					? `AMD resource error [${String(amdError.message)}]`
					: `AMD resource degraded, failed [${failedCount}] of [${totalCount}] gadgets`,
			);
		} else {
			logger.safeWarnWithoutCustomerData(
				'spa-apps.dashboard.content-container',
				`Retrying AMD resource, retry [${amdResourceRetries + 1}]`,
			);
			setAmdResourceRetries((n) => n + 1);
			amdRefresh();
		}
	}, [
		amdResourceRetries,
		amdLoading,
		amdError,
		amdData,
		isAmdResourceUnhealthy,
		isAmdResourceRetriesExceeded,
		amdRefresh,
	]);

	if (__SERVER__ || configLoading) {
		return <GenericLoadingView customHeight={customHeight} />;
	}

	if (configError != null || configData == null) {
		const errorCode = Number(configError?.message?.substring(configError.message.length - 3));
		if (DASHBOARD_PERMISSION_ERROR_CODE_LIST.includes(errorCode)) {
			return permissionErrorFallback;
		}
		return (
			<ViewExperienceFailureTracker
				location={analyticsExperience}
				failureEventAttributes={{
					error: configError ? configError.message : 'unknown error',
				}}
			>
				<JSErrorPageAsync />
			</ViewExperienceFailureTracker>
		);
	}

	if (isAmdResourceRetriesExceeded && amdError != null && amdData == null) {
		return (
			<ViewExperienceFailureTracker
				location={analyticsExperience}
				failureEventAttributes={{
					error: `AMD resource error [${String(amdError.message)}]`,
				}}
			>
				<JSErrorPageAsync />
			</ViewExperienceFailureTracker>
		);
	}

	return (
		// ThemeProvider will no longer be used in later versions of Atlaskit Grid
		// Remove when @atlaskit/page is updated
		<ThemeProvider theme={{}}>
			<ViewExperienceSuccessTracker location={analyticsExperience}>
				<AmdGadgetMonolithOverrideStyles />
				<AboveTheFoldContainer
					dashboardId={configData.id}
					layout={configData.layout}
					gadgets={configData.gadgets}
				>
					<GadgetAdditionalStateContainer>
						<CriticalSideEffects data={configData} saveCustomGadgetTitle={saveCustomGadgetTitle} />
						<RouteResourceValidator />
						{children}
					</GadgetAdditionalStateContainer>
				</AboveTheFoldContainer>
			</ViewExperienceSuccessTracker>
		</ThemeProvider>
	);
};
