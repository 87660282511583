// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See closest README.md

import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/graph-go-up-with-people.svg'; // eslint-disable-line jira/import/no-parent-imports, import/no-duplicates, jira/import/no-duplicates
import srcLight from '../../assets/graph-go-up-with-people.svg'; // eslint-disable-line jira/import/no-parent-imports, import/no-duplicates

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
// view box should be automatically set by svg values
const viewBox: SVGViewBox = [0, 0, 431, 406];
export { srcLight, srcDark, viewBox };
