import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyTextLabel: {
		defaultMessage: 'Copy link',
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'dashboard-view.copy-link-button.copy-link',
		description: 'Copy dashboard or dashboard gadget link button tooltip',
	},
	copiedLabel: {
		defaultMessage: 'Copied!',
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'dashboard-view.copy-link-button.copied',
		description: 'Copied dashboard or dashboard gadget link button tooltip',
	},
});
