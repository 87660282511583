import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCurrentPage, useSpaStateTransition } from '@atlassian/jira-spa-state-controller';

export const useDashboardScrollAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const currentPage = useCurrentPage();
	const [{ isInitialRender, currentPageId }] = useSpaStateTransition();
	const { startTime } = currentPage;
	const scrollStartTimestamp = useRef<number | null>(null);

	const debounceSendAnalytics = debounce((_scrollStartTimestamp) => {
		if (scrollStartTimestamp.current != null) return;

		const percentageOfPageScrolled = window.scrollY / window.innerHeight;

		if (percentageOfPageScrolled < 0.2) return;

		fireUIAnalytics(
			createAnalyticsEvent({ action: 'scrolled', actionSubject: 'page' }),
			'dashboardScroll',
			{
				isInitialRender,
				scrollStartTimeFromPageLoad: _scrollStartTimestamp - startTime,
				percentageOfPageScrolled,
				currentPageId,
			},
		);
		scrollStartTimestamp.current = _scrollStartTimestamp;
	}, 500);

	useEffect(() => {
		const fireScrollAnalytics = () => {
			debounceSendAnalytics(Date.now());
		};
		const resetScrollPosition = () => {
			window.scrollTo(0, 0);
		};

		window.addEventListener('scroll', fireScrollAnalytics);
		window.addEventListener('unload', resetScrollPosition);

		return () => {
			window.removeEventListener('scroll', fireScrollAnalytics);
			window.removeEventListener('unload', resetScrollPosition);
		};
	}, [debounceSendAnalytics]);

	const resetScrollStartTime = useCallback(() => {
		scrollStartTimestamp.current = null;
	}, []);

	return { scrollStartTimestamp, resetScrollStartTime };
};
