import React, { type PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { emit } from '@atlassian/forge-ui/events';
import { broadcastConnectEvent } from '@atlassian/jira-connect-utils';
import {
	type DashboardConfigType,
	CONNECT_GADGET_REFRESH_REQUEST_EVENT,
	FORGE_JIRA_DASHBOARD_GADGET_REFRESH_EVENT,
} from '@atlassian/jira-dashboard-common';
import { DashboardControllerContextProvider, type DashboardControllerContextType } from './context';

export type DashboardControllerArg = {
	id: DashboardConfigType['id'];
	automaticRefreshMs: number | null;
};

export const useDashboardController = ({
	automaticRefreshMs,
}: DashboardControllerArg): DashboardControllerContextType => {
	const [refreshId, setRefreshId] = useState<number>(Date.now());
	const [, setMostRecentlyAddedGadgetId] = useState<string | null>(null);

	const onRefresh = useCallback(() => {
		emit(FORGE_JIRA_DASHBOARD_GADGET_REFRESH_EVENT, { origin: 'dashboard' });
		broadcastConnectEvent(CONNECT_GADGET_REFRESH_REQUEST_EVENT, {}, { origin: 'dashboard' });

		setRefreshId(Date.now());
	}, []);

	useEffect(() => {
		if (automaticRefreshMs == null || automaticRefreshMs < 1) {
			return () => undefined;
		}
		// The interval is stored in a signed 32-bit int so any number above 2**31-1 would
		// become negative which will cause the callback to infinitely loop without any delay.
		// https://stackoverflow.com/a/12633556/1104483
		const refreshIntervalMs = Math.min(automaticRefreshMs, Math.pow(2, 31) - 1);
		const interval = setInterval(onRefresh, refreshIntervalMs);
		return () => {
			clearInterval(interval);
		};
	}, [automaticRefreshMs, onRefresh]);

	const onMount = useCallback((gadgetId: string, focusCallback: () => void) => {
		setMostRecentlyAddedGadgetId((mostRecentlyAddedGadgetId) => {
			if (gadgetId === mostRecentlyAddedGadgetId) {
				focusCallback();
				return null;
			}
			return mostRecentlyAddedGadgetId;
		});
	}, []);

	return {
		onRefresh,
		refreshId,
		onAddedGadget: setMostRecentlyAddedGadgetId,
		onMount,
	};
};

export type DashboardControllerProps = PropsWithChildren<DashboardControllerArg>;

export const DashboardController = ({ children, ...props }: DashboardControllerProps) => {
	const value = useDashboardController(props);
	return (
		<DashboardControllerContextProvider value={value}>
			{children}
		</DashboardControllerContextProvider>
	);
};
