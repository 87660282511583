import React, { type ReactNode } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button';
import { Link, generatePath, type Route, type LinkProps } from '@atlassian/jira-router';

type RouteLinkButtonProps = ButtonProps &
	LinkProps & {
		children: ReactNode;
	};

export const RouteLinkButton = ({ children, to, params, ...rest }: RouteLinkButtonProps) => (
	<Button
		component={Link}
		{...rest}
		{...(to && {
			// Link would not update when 'to' prop changes, so we need to force an update with key
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			key: (to as Route).name,
			// href prop is required to be passed to the Button component for Button to render as an anchor tag
			// instead of a button tag. Remove this line and related code after this issue is fixed for AK Button.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			href: generatePath((to as Route).path, params),
			to,
			params,
		})}
	>
		{children}
	</Button>
);
