import React, { type PropsWithChildren, useEffect } from 'react';
import Spinner from '@atlassian/jira-common-components-spinner';
import { log } from '@atlassian/jira-common-util-logging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { MetaAPIConsumer } from '@atlassian/jira-providers-meta/src';
import { Redirect } from '@atlassian/jira-router';

export type Props = PropsWithChildren<{ shouldLogUsage?: boolean }>;

const LOCATION = 'onboarding-check';

const FireUsageAnalytics = ({ url }: { url: string }) => {
	useEffect(() => {
		log.safeInfoWithoutCustomerData(LOCATION, `User was redirected to onboarding url ${url}`);
	}, [url]);

	return null;
};

export const OnboardingCheckOld = ({ shouldLogUsage, children }: Props) =>
	!__SERVER__ ? (
		<MetaAPIConsumer>
			{(getMeta) => {
				const meta = getMeta('ajs-onboarding-url');
				if (meta != null) {
					return (
						<>
							{shouldLogUsage && <FireUsageAnalytics url={meta} />}
							<Spinner />
							<Redirect to={meta} />
						</>
					);
				}
				return children;
			}}
		</MetaAPIConsumer>
	) : (
		<>{children}</>
	);

export const OnboardingCheckNew = ({ children }: Props) => children;

export const OnboardingCheck = componentWithCondition(
	() => fg('jira_remove_onboarding_interceptor'),
	OnboardingCheckNew,
	OnboardingCheckOld,
);
