import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import ExtensibleError from '@atlassian/jira-extensible-error';
import type { ErrorAttributes } from '../../types';

function is4xxError(statusCode?: number): boolean {
	return statusCode ? statusCode >= 400 && statusCode < 500 : false;
}

export class FetchConnectError extends ExtensibleError {
	skipSentry: boolean;

	statusCode?: number;

	constructor(message: string, statusCode?: number, cause?: Error, skipSentry?: boolean) {
		super(message, { cause });
		// the name is copied from `KNOWN_NETWORK_ERRORS_TYPES` in `src/packages/platform/utils/fetch/src/utils/is-error.tsx`
		this.name = cause && cause.name ? cause.name : 'NetworkError';
		this.statusCode = statusCode;
		this.skipSentry = typeof skipSentry === 'boolean' ? skipSentry : is4xxError(statusCode);
	}
}

export const handleConnectError = (
	id: string,
	error: FetchConnectError,
	errorAttrs: ErrorAttributes = {},
) => {
	const options = {
		error,
		meta: {
			id,
			packageName: 'jiraConnect',
		},
		attributes: {
			skipSentry: error.skipSentry,
			statusCode: error.statusCode,
			...errorAttrs,
		},
		sendToPrivacyUnsafeSplunk: true,
		skipSentry: error.skipSentry,
	};

	fireErrorAnalytics(options);

	if (error.cause) {
		fireErrorAnalytics({
			...options,
			attributes: {
				...options.attributes,
				isInnerError: true,
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error.cause as Error,
		});
	}
};
