import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics';
import logger from '@atlassian/jira-common-util-logging/src/log';
import {
	VIEW_WRM_GADGET_EXPERIENCE,
	GADGET_ERROR_TYPE,
	GADGET_METRICS_TYPE,
	type AmdGadgetClass,
	type AmdGadgetInstanceType,
	type ReportGadgetMetricsCallback,
	type GadgetErrorType,
	type GadgetMetricsEventType,
	type GadgetContentType,
} from '@atlassian/jira-dashboard-common';
import { decodeUserPreferences } from '@atlassian/jira-dashboard-user-preference';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { useDashboardAMDModulesResource } from '@atlassian/jira-router-resources-dashboard';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller';
import { GadgetErrorFallback, SkeletonGadgetContent } from '../../../../common';
import { useEventPublisher } from '../../../../controllers/event-publisher';
import { useGadgetSetPreferences } from '../../../../controllers/gadget';
import { useIsGlobalsReady } from '../../../../controllers/is-globals-ready';
import { useLinkInterceptor } from '../../../../controllers/link-interceptor';
import { useMessageBus } from '../../../../controllers/message-bus';
import {
	GADGET_RENDER_CONTAINER_EVENT,
	GADGET_START_EVENT,
	GADGET_RENDERING_FINISHED_EVENT,
	WRM_GADGET_METRICS,
} from '../../../../controllers/metrics';
import { getInlineGadgetApi } from '../../../../utils';
import { Container, GadgetContainer, SkeletonWrapper } from './styled';
import type { AmdProps } from './types';

const expAttributes = {
	analyticsSource: 'dashboard',
	experience: VIEW_WRM_GADGET_EXPERIENCE,
	application: null,
	edition: null,
	additionalAttributes: {},
} as const;

const reportError = (
	gadgetId: string,
	reportGadgetMetrics: ReportGadgetMetricsCallback,
	error: Error,
) => {
	reportGadgetMetrics(gadgetId, GADGET_START_EVENT, 'ErrorMessage');
	reportGadgetMetrics(gadgetId, GADGET_RENDERING_FINISHED_EVENT, 'ErrorMessage');
	sendExperienceAnalytics({
		...expAttributes,
		wasExperienceSuccesful: false,
	});
	fireErrorAnalytics({
		error,
		meta: {
			id: VIEW_WRM_GADGET_EXPERIENCE,
			packageName: 'jiraDashboardInternalCommon',
		},
	});
};

export const Amd = ({
	dashboardId,
	gadgetId,
	amdModule,
	context,
	defaultTitle,
	userPrefs,
	inlineHtml,
	isEditable,
	isLoading,
	isInEditMode,
	setIsLoading,
	setDefaultTitle,
	shouldUseCache,
	isMaximized,
	onEditModeCancel,
}: AmdProps) => {
	const [{ currentPageId }] = useSpaStateTransition();
	const { loading: loadingB, data } = useDashboardAMDModulesResource();
	const { amdModules = null } = data ?? {};
	const setPreferences = useGadgetSetPreferences();
	const preference = useMemo(() => decodeUserPreferences(userPrefs?.fields), [userPrefs?.fields]);
	const module = useRef<AmdGadgetInstanceType | null>(null);
	const divRef = useRef<HTMLDivElement>(null);
	const { addEventHandler, triggerEvent, removeEventHandlers } = useEventPublisher();
	const isGlobalsReady = useIsGlobalsReady();
	const isRenderable =
		(isGlobalsReady && !loadingB && amdModules?.[amdModule ?? ''] != null) ||
		module.current != null;
	const isSkeleton = isLoading && !isInEditMode;
	const [, { broadcastMessage: broadcastMessageBusListener }] = useMessageBus();
	const [gadgetError, setGadgetError] = useState<GadgetErrorType | null>(null);

	useLinkInterceptor(divRef.current, isLoading);

	const reportGadgetMetrics = useCallback<ReportGadgetMetricsCallback>(
		(
			gid: string,
			eventType: GadgetMetricsEventType,
			contentType: GadgetContentType,
			markName?: string,
			markStage?: string,
		) => {
			broadcastMessageBusListener(
				GADGET_METRICS_TYPE.REPORT,
				{
					eventType,
					contentType,
					markName,
					markStage,
					source: WRM_GADGET_METRICS,
				},
				{ gadgetId: gid, pageId: currentPageId },
			);
		},
		[broadcastMessageBusListener, currentPageId],
	);

	const gadgetApi = useMemo(
		() =>
			getInlineGadgetApi({
				gadgetId,
				amdModule: amdModule ?? '', // This will never happen, we're just want Flow to shut up
				context,
				defaultTitle,
				isEditable,
				closeEdit: onEditModeCancel,
				setIsLoading,
				ref: divRef,
				setDefaultTitle,
				reportGadgetMetrics,
				dashboardId,
				addEventHandler,
				removeEventHandlers,
				triggerEvent,
				setPreferences,
				shouldUseCache,
				view: isMaximized ? 'canvas' : 'default',
				setError: setGadgetError,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	// IMPORTANT - this effect must come before AMD initialisation effect
	useLayoutEffect(() => {
		if (inlineHtml == null || divRef.current == null) {
			return;
		}
		divRef.current.innerHTML = '';
		divRef.current.appendChild(document.createRange().createContextualFragment(inlineHtml));
	}, [inlineHtml]);

	useLayoutEffect(() => {
		(async () => {
			// TODO - amdModule missing should be a hard error not a type refinement
			if (!isRenderable || amdModule == null) {
				return;
			}

			// first time initialisation
			if (module.current == null) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const Module = (amdModules as Record<string, AmdGadgetClass>)[amdModule];
				try {
					module.current = new Module(gadgetApi);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					reportError(gadgetId, reportGadgetMetrics, error);
					setGadgetError(GADGET_ERROR_TYPE.VIEW_ERROR);
					logger.safeErrorWithoutCustomerData(
						'spa-apps.dashboard.gadget.amd',
						`Failed to instantiate AMD module [${amdModule}]`,
						error,
					);
				}
			}

			// re-render on edit-mode config/view change
			if (module.current != null) {
				logger.safeInfoWithoutCustomerData(
					'spa-apps.dashboard.gadget.amd',
					`Render AMD module [${amdModule}] in ${isInEditMode ? 'Edit' : 'View'} mode`,
				);
				reportGadgetMetrics(
					gadgetId,
					GADGET_RENDER_CONTAINER_EVENT,
					isInEditMode ? 'Config' : 'View',
					undefined,
					undefined,
				);

				const render =
					(isInEditMode && module.current?.renderEdit) ||
					(preference.isConfigured && module.current?.render) ||
					null;

				if (render == null) {
					reportError(gadgetId, reportGadgetMetrics, new Error('gadget render is null'));
					setGadgetError(GADGET_ERROR_TYPE.VIEW_ERROR);
					// NOTE - this is not a plausible case but log it anyway, ideally we'd want type guarantees
					logger.safeErrorWithoutCustomerData(
						'spa-apps.dashboard.gadget.amd',
						`Render AMD module [${amdModule}] failure in ${
							isInEditMode ? 'Edit' : 'View'
						} mode, render method missing`,
					);
				} else {
					try {
						render.call(
							module.current,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
							(window as any).jQuery(divRef.current),
							preference,
						);
						setGadgetError(null);
						sendExperienceAnalytics({ ...expAttributes, wasExperienceSuccesful: true });
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						reportError(gadgetId, reportGadgetMetrics, error);
						setGadgetError(GADGET_ERROR_TYPE.VIEW_ERROR);
						logger.safeErrorWithoutCustomerData(
							'spa-apps.dashboard.gadget.amd',
							`Render AMD module [${amdModule}] failure in ${
								isInEditMode ? 'Edit' : 'View'
							} mode, render method throws error`,
							error,
						);
					}
					triggerEvent('afterRender');
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInEditMode, preference, isRenderable]);

	useEffect(
		// clean up, in separate hook because we only want it called once on unmount
		() => () => {
			if (module.current) {
				module.current = null;
			}
		},
		[],
	);

	return gadgetError != null ? (
		<GadgetErrorFallback errorType={gadgetError} id={gadgetId} />
	) : (
		<Container>
			<GadgetContainer id={`gadget-${gadgetId}`} ref={divRef} isHidden={isSkeleton} />
			{isSkeleton && (
				<SkeletonWrapper data-testid="dashboard-internal-common.ui.gadget.gadget-inner.amd.loading-skeleton">
					<SkeletonGadgetContent dashboardId={dashboardId} gadgetId={gadgetId} />
				</SkeletonWrapper>
			)}
		</Container>
	);
};
