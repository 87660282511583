import { useCallback } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service';
import type { Dashboard } from '@atlassian/jira-dashboards-common/src/rest/types';
import { performGetRequest } from '@atlassian/jira-fetch';

export type Data = Dashboard;

export type State = {
	loading: boolean;
	error: Error | undefined;
	data: Data | undefined;
};

export type ServiceResponse = State & {
	fetch: () => Promise<Data | undefined>;
};

export const useFetchDashboardConfig = (dashboardId: string): ServiceResponse => {
	const fetchDashboardConfig = useCallback(
		() => performGetRequest(`/rest/api/latest/dashboard/${dashboardId}`),
		[dashboardId],
	);
	const { data, loading, error, fetch } = useService<Data>(fetchDashboardConfig, {});

	return { data, loading, error, fetch };
};
