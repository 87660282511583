import type { SetterOrState } from '@atlassian/jira-react-gadgets-common';
import type { ReactGadgetsState, ReactGadgetState } from '@atlassian/jira-react-gadgets-definition';

export const lensReactGadgets =
	<
		T extends {
			reactGadgetsState: ReactGadgetsState | null;
		} | null,
	>(
		updateGadgets: (arg1: ReactGadgetsState | null) => ReactGadgetsState | null,
	): ((arg1: T) => T) =>
	(resource: T) =>
		resource && {
			...resource,
			reactGadgetsState: updateGadgets(resource.reactGadgetsState),
		};

export const lensReactGadget = <
	T extends {
		reactGadgetsState: ReactGadgetsState | null;
	} | null,
>(
	id: string,
	setterOrValue: SetterOrState<ReactGadgetState>,
): ((arg1: T) => T) => {
	const setter =
		typeof setterOrValue === 'function'
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(setterOrValue as (arg1: ReactGadgetState | Error | null) => ReactGadgetState | null)
			: (): ReactGadgetState | null => setterOrValue;

	return lensReactGadgets((reactGadgetsState) => {
		if (!reactGadgetsState || !(id in reactGadgetsState)) {
			return reactGadgetsState;
		}

		const prevStateOrError = reactGadgetsState[id];
		const newState = setter(prevStateOrError);
		const prevState =
			prevStateOrError === null || prevStateOrError instanceof Error ? {} : prevStateOrError;
		return {
			...reactGadgetsState,
			[id]: newState === null ? null : { ...prevState, ...newState },
		};
	});
};
