import { useEffect } from 'react';
import logger from '@atlassian/jira-common-util-logging/src/log';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller';
import { useDashboardAnalytics } from '../../../controllers/dashboard-analytics';
import { getDashboardFeatureFlags } from '../../../utils';

const TEN_MIN = 1000 * 60 * 10;

// We need to replace '.' in FF keys with '_' to match BM3 and avoid confusion in Splunk
const sanitiseKeys = (
	featureFlags: null | {
		[key: string]: unknown;
	},
) =>
	Object.fromEntries(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(featureFlags || {}).map(([key, value]: [any, any]) => [
			key.replace(/\./g, '_'),
			value,
		]),
	);

export const usePageStatusLogger = (interval: number) => {
	const fireAnalytics = useDashboardAnalytics();
	const [{ isInitialRender, currentPageId, lastTransition }] = useSpaStateTransition();

	useEffect(() => {
		const logFeatureFlags = () => {
			const featureFlags = JSON.stringify(sanitiseKeys(getDashboardFeatureFlags()));
			const attributes = { isInitialRender, lastTransition, currentPageId, featureFlags };

			logger.safeInfoWithoutCustomerData(
				'spa-apps.dashboard.page-status',
				Object.entries(attributes) // eslint-disable-next-line @typescript-eslint/no-explicit-any
					.map(([k, v]: [any, any]) => `${k} [${String(v ?? null)}]`)
					.join(' '),
			);
			fireAnalytics({
				eventType: OPERATIONAL_EVENT_TYPE,
				actionSubject: 'dashboard.page-status',
				actionSubjectId: 'featureFlags',
				action: 'logged',
				attributes,
			});
		};
		logFeatureFlags();
		const logInterval = setInterval(logFeatureFlags, Math.max(TEN_MIN, interval) || TEN_MIN);

		return () => clearInterval(logInterval);
	}, [interval, fireAnalytics, isInitialRender, currentPageId, lastTransition]);
};
