import { useCallback } from 'react';
import noop from 'lodash/noop';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	fireTrackAnalytics,
	fireOperationalAnalytics,
	fireScreenAnalytics,
	SCREEN_EVENT_TYPE,
	UI_EVENT_TYPE,
	TRACK_EVENT_TYPE,
	OPERATIONAL_EVENT_TYPE,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { UseDashboardAnalyticsType } from './types';

export const useDashboardAnalytics: UseDashboardAnalyticsType = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		({ eventType, actionSubject, action, actionSubjectId, attributes = {} }) => {
			let fireAnalytics = noop;
			// All of the events have exactly the same signature and transformations underneath.
			switch (eventType) {
				case TRACK_EVENT_TYPE:
					fireAnalytics = fireTrackAnalytics;
					break;
				case OPERATIONAL_EVENT_TYPE:
					fireAnalytics = fireOperationalAnalytics;
					break;
				case UI_EVENT_TYPE:
					fireAnalytics = fireUIAnalytics;
					break;
				case SCREEN_EVENT_TYPE:
					fireAnalytics = fireScreenAnalytics;
					break;
				default:
					fireAnalytics = noop;
			}
			fireAnalytics(
				createAnalyticsEvent({}),
				`${actionSubject} ${action}`,
				actionSubjectId,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				attributes as Attributes,
			);
		},
		[createAnalyticsEvent],
	);
};
