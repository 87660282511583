import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers';
import {
	GADGET_LOCAL_STORAGE_NAME,
	DASHBOARD_LOCAL_STORAGE_KEY_NAME,
	GADGET_DEFAULT_HEIGHT,
} from '@atlassian/jira-dashboard-common';

type Attribute = {
	[key: string]: string;
};

export type Cookie = {
	[key: string]: Attribute | undefined;
};

export const getMappedCookieInfo = (cookieData: string): Cookie => {
	if (!cookieData) {
		return {};
	}

	const cookieInfos = cookieData.split('|').filter((cookieInfo) => cookieInfo.trim().length);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return cookieInfos.reduce<Record<string, any>>((acc, cookieInfo) => {
		const COOKIE_INFO_PATTERN = /(?<cookieKey>.*)=(?<cookieValue>.*)/;
		const matchRes = cookieInfo.match(COOKIE_INFO_PATTERN);
		if (!matchRes) {
			return acc;
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { cookieKey, cookieValue } = matchRes.groups as {
			cookieKey: string;
			cookieValue: string;
		};

		if (cookieKey.includes(':')) {
			const [gadgetId, gadgetAttributeName] = cookieKey.split(':');
			acc[gadgetId] = acc[gadgetId] || {};
			acc[gadgetId][gadgetAttributeName] = cookieValue;
		} else if (cookieKey.includes('-')) {
			const [, gadgetId, gadgetAttributeName] = cookieKey.split('-');
			acc[gadgetId] = acc[gadgetId] || {};
			acc[gadgetId][gadgetAttributeName] = cookieValue;
		}

		return acc;
	}, {});
};

export const stringifyMappedCookieInfo = (mappedCookieInfo: Cookie): string => {
	let res = '';
	if (typeof mappedCookieInfo !== 'object') {
		return res;
	}

	Object.keys(mappedCookieInfo).forEach((id) => {
		const attributeNames = mappedCookieInfo[id];

		if (typeof attributeNames !== 'object') {
			return;
		}

		Object.keys(attributeNames).forEach((attributeName) => {
			const attributeValue = attributeNames[attributeName];

			if (!attributeValue) {
				return;
			}

			// If res already has contents add the separator
			if (res.length) {
				res += '|';
			}

			if (attributeName.includes('fh')) {
				res += `gadget-${id}-${attributeName}=${attributeValue}`;
			} else {
				res += `${id}:${attributeName}=${attributeValue}`;
			}
		});
	});

	return res;
};

export const getCleanedCookieData = () => {
	const localStorage = createLocalStorageProvider('');
	const cookieData = localStorage.get(GADGET_LOCAL_STORAGE_NAME);

	if (typeof cookieData !== 'string') {
		return '';
	}

	// TODO: Remove when monolith manipulation of gadget local storage is removed.
	// In particular around frame heights of gadgets coming from AG.Cookie.js
	// Issue is the FE local storage provider adds leading and trailing quotations
	// while the old local storage value does not have it. This causes issues when
	// the local storage is dynamically updated by the old monolith code
	// Eg. Might end up with something like '"foo=1"|bar=2'
	// To handle this for now, we remove all quotations
	return cookieData.replace(/"/g, '');
};

export const getGadgetDataFromLocalStorage = (id: string, gadgetDataKey: string) => {
	const cleanedCookieData = getCleanedCookieData();

	const mappedCookieInfo = getMappedCookieInfo(cleanedCookieData);
	const gadgetInfo = mappedCookieInfo[id];
	return gadgetInfo?.[gadgetDataKey];
};

export const putGadgetDataFromLocalStorage = (id: string, gadgetDataKey: string, value: string) => {
	const cleanedCookieData = getCleanedCookieData();

	const mappedCookieInfo = getMappedCookieInfo(cleanedCookieData);
	mappedCookieInfo[id] = mappedCookieInfo[id] ?? {};
	if (mappedCookieInfo[id]) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		(mappedCookieInfo[id] as Record<string, any>)[gadgetDataKey] = value;
	}

	const updatedCookieData = stringifyMappedCookieInfo(mappedCookieInfo);

	const localStorage = createLocalStorageProvider('');
	localStorage.set(GADGET_LOCAL_STORAGE_NAME, updatedCookieData);
};

export const deleteGadgetDataFromLocalStorage = (id: string, gadgetDataKey: string) => {
	const cleanedCookieData = getCleanedCookieData();

	const mappedCookieInfo = getMappedCookieInfo(cleanedCookieData);
	if (mappedCookieInfo[id]) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		delete (mappedCookieInfo[id] as Record<string, any>)[gadgetDataKey];
	}

	const updatedCookieData = stringifyMappedCookieInfo(mappedCookieInfo);
	const localStorage = createLocalStorageProvider('');
	localStorage.set(GADGET_LOCAL_STORAGE_NAME, updatedCookieData);
};

export const updateLocalStorageHeight = (
	dashboardId: string | null | undefined,
	gadgetId: string,
	height?: number | null,
) => {
	if (dashboardId == null || height == null) {
		return;
	}

	const heightAsInt = Math.round(height);

	const localStorage = createLocalStorageProvider('');
	const dashboardLocalData = localStorage.get(DASHBOARD_LOCAL_STORAGE_KEY_NAME) ?? {};
	dashboardLocalData[dashboardId] = dashboardLocalData[dashboardId] ?? {};

	const dashboardData = dashboardLocalData[dashboardId];
	dashboardData[gadgetId] = dashboardData[gadgetId] ?? {};

	const gadgetData = dashboardData[gadgetId];
	const storedHeight = gadgetData.height;

	if (storedHeight === heightAsInt) {
		return;
	}

	gadgetData.height = heightAsInt;
	localStorage.set(DASHBOARD_LOCAL_STORAGE_KEY_NAME, dashboardLocalData);
};

export const getLocalStorageHeight = (dashboardId?: string, gadgetId?: string): number => {
	const localStorage = createLocalStorageProvider('');
	const dashboardLocalData = localStorage.get(DASHBOARD_LOCAL_STORAGE_KEY_NAME);
	const gadgetLocalHeight =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		dashboardLocalData?.[dashboardId as string]?.[gadgetId as string]?.height;

	return gadgetLocalHeight !== 0 && gadgetLocalHeight != null
		? gadgetLocalHeight
		: GADGET_DEFAULT_HEIGHT;
};

export const getAllLocalStorageHeights = (
	dashboardId: string,
): Record<string, { height: number }> => {
	const localStorage = createLocalStorageProvider('');
	const dashboardLocalData = localStorage.get(DASHBOARD_LOCAL_STORAGE_KEY_NAME);
	return dashboardLocalData?.[dashboardId] ?? {};
};
