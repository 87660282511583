import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics';
import logger from '@atlassian/jira-common-util-logging/src/log';
import {
	GADGET_ERROR_TYPE,
	SAVE_FORGE_GADGET_CONFIG_PREFERENCES,
	type GadgetData,
} from '@atlassian/jira-dashboard-common';
import type { ItemProperties, UserPreferenceType } from '@atlassian/jira-dashboard-user-preference';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import {
	fireOperationalEditFailedEvent,
	fireTrackEditedEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/dashboard-gadget-edit';

const expAttributes = {
	analyticsSource: 'dashboard',
	experience: SAVE_FORGE_GADGET_CONFIG_PREFERENCES,
	application: null,
	edition: null,
	additionalAttributes: {},
} as const;

export const savePreferences = async (
	data: GadgetData,
	preferences: ItemProperties,
	setPreferences: (arg1: ItemProperties, arg2?: () => void) => Promise<void> | null,
	setError: (
		arg1: typeof GADGET_ERROR_TYPE.VIEW_ERROR | typeof GADGET_ERROR_TYPE.CONFIG_ERROR | null,
	) => void,
	onComplete: () => void,
) => {
	const { title: defaultTitle } = data;

	// the backend checks that each user pref value is valid json
	// so we need to stringify string values eg ""value""
	// this is done inside the gadget for other gadget types but
	// we need to do it here for forge
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const newUserPrefs: Record<string, any> = {};
	Object.entries(preferences).forEach(([key, value]) => {
		newUserPrefs[key] = typeof value === 'string' ? JSON.stringify(value) : value;
	});

	const maybePromise = setPreferences(newUserPrefs, onComplete);
	if (!maybePromise) {
		return;
	}
	try {
		await maybePromise;
		setError(null);
		sendExperienceAnalytics({
			...expAttributes,
			wasExperienceSuccesful: true,
		});
		fireTrackEditedEvent('dashboardGadgetEdit');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		logger.safeErrorWithoutCustomerData(
			'spa-apps.dashboard.gadget.forge',
			`${e.message} [${defaultTitle}]`,
		);
		setError(GADGET_ERROR_TYPE.CONFIG_ERROR);
		sendExperienceAnalytics({
			...expAttributes,
			wasExperienceSuccesful: false,
		});
		fireErrorAnalytics({
			error: e,
			meta: {
				id: SAVE_FORGE_GADGET_CONFIG_PREFERENCES,
				packageName: 'jiraDashboardInternalCommon',
			},
		});
		fireOperationalEditFailedEvent('dashboardGadgetEdit', {
			error: e,
		});
	}
};

// user prefs with a string value are encoded like "&quot;value&quot;" so we need to
// decode them for forge gadgets
const decodeQuotes = (value: string) => value.replace(/(?:&quot;)/gm, '"');

export const transformUserPrefsForForgeAppConsumption = (
	userPrefsFields?: UserPreferenceType[],
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const transformedUserPrefs: Record<string, any> = {};
	if (userPrefsFields !== undefined) {
		userPrefsFields.forEach((field) => {
			if (field.name !== 'isConfigured') {
				// the backend stringifies all configuration values,
				// we need to parse them here for forge gadgets
				transformedUserPrefs[field.name] = JSON.parse(decodeQuotes(field.value));
			}
		});
	}

	return transformedUserPrefs;
};
