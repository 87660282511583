// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import { waitForJQuery } from './common';
import type { ACJSHost } from './types';

export type { ACJSHost };
const installConnectJs = async () => {
	await waitForJQuery();

	const loader = await import(/* webpackChunkName: "async-connect-js-api" */ './loader');
	await loader.performSideEffects();

	return window.connectHost;
};

let installConnectJsPromise: Promise<ACJSHost> | null = null;

export const setupConnectJs = async (): Promise<ACJSHost> => {
	// Only pages in the SPA will benefit from modernising connect
	if (window.__SPA__) {
		if (installConnectJsPromise === null) {
			installConnectJsPromise = installConnectJs();
		}

		return installConnectJsPromise;
	}
	// eslint-disable-next-line jira/wrm/no-load-bridge
	await loadBridge({
		name: 'ac/create',
		wrmKeys: [
			'wrc!com.atlassian.plugins.atlassian-connect-plugin:jira-atlassian-connect-resources-v5',
			// Undeclared Dependency: jira/navigation
			'wrc!com.atlassian.jira.jira-atlaskit-plugin:sidebar',
		],
	});

	return window.connectHost;
};
