import React, { useCallback, type ReactElement } from 'react';
import { Flex } from '@atlaskit/primitives';
import {
	REFRESH_LABEL_UPDATE_INTERVAL,
	type GadgetData,
	type GadgetDisplay,
	CopyLinkButton,
} from '@atlassian/jira-dashboard-common';
import {
	GadgetInner,
	GadgetTitleReadOnly,
	GadgetTitleWrapper,
	GadgetControllerContextConsumer,
	isConnectGadget,
	GadgetReadOnlyController,
	type useConnectGadgetTitles,
	GadgetToolbar,
	LastRefreshed,
	GadgetLayoutWithAnalytics,
	useDashboardAnalytics,
} from '@atlassian/jira-dashboard-internal-common';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';

export type GadgetReadOnlyProps = {
	dashboardId: string;
	data: GadgetData;
	display: GadgetDisplay;
	unconfiguredFallback: ReactElement;
	refreshId: number;
	onMaximizeOrRestore: (arg1: boolean) => void;
	onRegisterConnectGadget: ReturnType<typeof useConnectGadgetTitles>['registerConnectGadget'];
	onUnregisterConnectGadget: ReturnType<typeof useConnectGadgetTitles>['unregisterConnectGadget'];
};

const noop = () => undefined;

export const GadgetReadOnly = ({
	dashboardId,
	data,
	display,
	unconfiguredFallback,
	refreshId,
	onRegisterConnectGadget,
	onUnregisterConnectGadget,
	onMaximizeOrRestore,
}: GadgetReadOnlyProps) => {
	const { id, isMaximizable, titleUrl, forge } = data;
	const isFirstPartyGadget = !isConnectGadget(data) && !forge;
	const fireAnalytics = useDashboardAnalytics();

	const onCopyLinkHandler = useCallback(() => {
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			action: 'clicked',
			actionSubject: 'dashboard.copy-link-button',
			actionSubjectId: 'gadget',
		});
	}, [fireAnalytics]);

	return (
		<GadgetReadOnlyController data={data} display={display} dashboardRefreshId={refreshId}>
			{/* TODO - these are also in context so should eventually can be wired more directly */}
			<GadgetControllerContextConsumer>
				{({
					isDisplayed,
					isMaximized,
					isMinimized,
					onMinimizeOrRestore,
					shouldUseCache,
					isLoading,
					setIsLoading,
					lastRefreshed,
					isUnconfigured,
					title,
					setDefaultTitle,
					onRenameSave,
				}) => (
					<GadgetLayoutWithAnalytics
						gadgetData={data}
						isDisplayed={isDisplayed}
						isContentHidden={isMinimized}
						header={
							<Flex alignItems="center">
								<GadgetTitleWrapper id={`gadget-title-${id}`}>
									<GadgetTitleReadOnly
										title={title}
										titleUrl={titleUrl}
										forgeEnvironment={forge?.environment}
										forgeEnvironmentKey={forge?.environmentKey}
									/>
								</GadgetTitleWrapper>
								<GadgetToolbar
									gadgetId={id}
									title={title}
									onMinimizeOrRestore={onMinimizeOrRestore}
									isMinimized={isMinimized}
									isMaximizable={isMaximizable}
									onMaximizeOrRestore={onMaximizeOrRestore}
									isMaximized={isMaximized}
									additionalToolbarItems={
										isMaximizable && (
											<CopyLinkButton
												dashboardId={dashboardId}
												gadgetId={id}
												onCopyClick={onCopyLinkHandler}
											/>
										)
									}
								/>
							</Flex>
						}
						footer={
							isFirstPartyGadget && (
								<LastRefreshed
									lastRefreshed={lastRefreshed}
									updateInterval={REFRESH_LABEL_UPDATE_INTERVAL}
								/>
							)
						}
					>
						<div data-testid="dashboard-view.ui.gadget-readonly.gadget-wrapper">
							<GadgetInner
								dashboardId={dashboardId}
								data={data}
								isUnconfigured={isUnconfigured}
								isEditModeEnabled={false}
								isLoading={isLoading}
								isInEditMode={false}
								shouldUseCache={shouldUseCache}
								setIsLoading={setIsLoading}
								setDefaultTitle={setDefaultTitle}
								onEditModeCancel={noop}
								isMaximized={isMaximized}
								unconfiguredFallback={unconfiguredFallback}
								onRegisterConnectGadget={onRegisterConnectGadget}
								onUnregisterConnectGadget={onUnregisterConnectGadget}
								setTitle={onRenameSave}
							/>
						</div>
					</GadgetLayoutWithAnalytics>
				)}
			</GadgetControllerContextConsumer>
		</GadgetReadOnlyController>
	);
};
