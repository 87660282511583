import React, { useRef, useEffect, useState } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Introduction from '@atlassian/jira-illustrations/src/ui/adg4/jira/heroes/components/graph-go-up-with-people';
import UFOLoadHold from '@atlassian/ufo-load-hold';
import { useOnGadgetRenderEffect } from '../../../../controllers/above-the-fold';
import { useLinkInterceptor } from '../../../../controllers/link-interceptor';

export type InlineHtmlProps = {
	isLoading: boolean;
	id: string;
	inlineHtml: string;
	setIsLoading: (arg1: boolean) => void;
};

export const InlineHtml = ({ id, inlineHtml, isLoading, setIsLoading }: InlineHtmlProps) => {
	const inlineHtmlRef = useRef<HTMLDivElement | null>(null);
	const [hasIntroLogo, setHasIntroLogo] = useState<boolean>(false);
	useLinkInterceptor(inlineHtmlRef.current, isLoading);

	useEffect(() => {
		const slotHtml = document.createRange().createContextualFragment(inlineHtml);
		if (inlineHtmlRef.current) {
			inlineHtmlRef.current.innerHTML = '';
			inlineHtmlRef.current.appendChild(slotHtml);

			const introLogoElement = inlineHtmlRef.current.querySelector('.intro-logo');
			if (introLogoElement) {
				setHasIntroLogo(true);
			}
		}
		setIsLoading(false);
	}, [inlineHtml, setIsLoading]);

	useOnGadgetRenderEffect(id, inlineHtmlRef.current);

	return (
		<>
			<UFOLoadHold name="inline-html-gadget" hold={isLoading} />
			<Box xcss={hasIntroLogo && introStyles}>
				{hasIntroLogo && <Introduction alt="" />}
				<div id={`gadget-${id}`} ref={inlineHtmlRef} />
			</Box>
		</>
	);
};

const introStyles = xcss({
	overflow: 'auto',
	height: '160px',
	display: 'flex',
	justifyContent: 'space-around',
	padding: 'space.200',
	gap: 'space.200',
});
