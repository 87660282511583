import { useRef } from 'react';
import type { Dashboard } from '@atlassian/jira-dashboards-common/src/rest/types';
import { OPEN_SHARE, PUBLIC_SHARE } from '@atlassian/jira-shared-types';

export const getIsDashboardShareable = (data: Dashboard | undefined): boolean => {
	if (!data || data.sharePermissions.length === 0) {
		return false;
	}
	const shareType = data.sharePermissions[0].type;
	return shareType === PUBLIC_SHARE || shareType === OPEN_SHARE;
};

export const useOnce = (callback: () => void) => {
	const done = useRef(false);
	if (!done.current) {
		callback();
		done.current = true;
	}
};
