import React, { Component } from 'react';
import { FavouriteChangeConsumer } from '@atlassian/jira-favourite-change-provider/src';
import { getFavourite } from './services/get-favourite';
import FavouriteButton, { type OwnProps } from './view';

// eslint-disable-next-line jira/react/no-class-components
export default class FavouriteButtonWrapper extends Component<OwnProps> {
	render() {
		return (
			<FavouriteChangeConsumer>
				{(favouriteChangeContext) => (
					<FavouriteButton {...this.props} favouriteChangeContext={favouriteChangeContext} />
				)}
			</FavouriteChangeConsumer>
		);
	}
}

export { getFavourite };
